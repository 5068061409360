import { logger } from "@cr/core/logger";
import { action, computed, observable, when } from "mobx";

interface Constructor<T> {
  new (...args: any[]): T;
  __proto__?: { constructor: { name: string } };
}

/**
 * Represents the states that a Store can be in
 *
 * @enum
 */
export enum StoreStatus {
  /** Store has been created, but not yet initialized */
  Unintialized = "Unintialized",
  /** Store is (re)loading data */
  Loading = "Loading",
  /** Store has finished loading data and is ready to be consumed */
  Loaded = "Loaded",
  /** Indicates an error initializing the store or loading its data */
  Error = "Error"
}

export interface StoreArguments {
  $id?: number;
  $createdBy?: string;
  getStore: StoreFactory;
}

export interface StoreConstructor<TStore extends IStore> extends Constructor<TStore> {
  isPersistent?: boolean;
  storeName?: Symbol; // tslint:disable-line:ban-types
}

export type StoreFactory = <TStore extends IStore>(StoreType: StoreConstructor<TStore>) => TStore;

/**
 * Defines the interface that most Stores will (optionally) implement
 *
 * @public
 */
// tslint:disable-next-line:interface-name
export interface IStore {
  /**
   * The globally unique idenfifier for a single store instance
   *
   * @ignore For debugging only
   */
  $id?: number;

  /**
   * The component which originally requested the store
   *
   * @ignore For debugging only
   */
  $createdBy?: string;

  /**
   * Indicates the store's current status
   *
   * @public
   */
  status: StoreStatus;

  /**
   * Activates the Store before it's needed
   *
   * NOTE: this occurs many times, so stores the implementation must be efficient;
   *       e.g. if a store has already initialized/loaded data in response to a previous
   *       activation, it should not do so again
   *
   * @public
   */
  activate?: (currentHash?) => void;

  /**
   * Unload the store, cleaning up any resources and reducing memory footprint as much as possible
   */
  dispose?: () => void;

  /**
   * Internal method used for scoping of stores.
   * @access package
   */
  getStore: StoreFactory;

  /**
   * Triggers the Store's cache invalidation mechanisms (if applicable)
   */
  invalidateCache?: () => void;

  /**
   * Resets the Store to its initial or previous state.
   * (What this means depends on the Store)
   *
   * @public
   */
  reset?: () => void;
}

/**
 * A base class with a default implementation of `IStore` members
 * and a variety of helper methods to make Store development easier.
 *
 * Note the following tips:
 *  * it is _recommended_ - though not required - that Stores derive from this class
 *  * this implementation makes assumptions based on the use case of supporting a single Entity type;
 *    though implementing multiple Entity types in a single store is a supported pattern,
 *    just be warned that the helper methods may not behave the way you might expect.
 *
 * @see IStore
 * @public
 */
@logger
export abstract class Store implements IStore {
  $id: number;
  $createdBy: string;

  @observable private _status = StoreStatus.Unintialized;

  /** @inheritdoc */
  @computed
  get hasError() {
    return this._status === StoreStatus.Error;
  }

  /** @inheritdoc */
  @computed
  get isLoading() {
    return this._status === StoreStatus.Loading || this._status === StoreStatus.Unintialized;
  }

  /** @inheritdoc */
  @computed
  get status() {
    return this._status;
  }

  constructor({ $id, $createdBy, getStore }: StoreArguments) {
    if (getStore == null) {
      console.warn("getStore cannot be null");
    }

    this.$id = $id;
    this.$createdBy = $createdBy;
    this.getStore = getStore;
  }

  /** @inheritdoc */
  activate(): void {
    // Basic activation logic which calls the `loadData()` method only once,
    // when the store hasn't yet been initialized.
    if (this.status === StoreStatus.Unintialized) {
      // `loadData()` should set this, but we're going to set it anyway just to avoid double-initialization
      this._status = StoreStatus.Loading;

      this.loadData();
    }
  }

  /** @inheritDoc */
  dispose() {
    this.setStatus(StoreStatus.Unintialized);
  }

  /**
   * @inheritdoc
   * @access package
   */
  getStore: StoreFactory;

  /** @inheritdoc */
  async waitForLoaded() {
    return new Promise((resolve, reject) => {
      when(() => this.status === StoreStatus.Loaded, resolve as (() => void));
      when(() => this.status === StoreStatus.Error, reject);
    });
  }

  /**
   * Loads any data required by the store.
   *
   * Called during activation (by `activate()`) when the store has not yet been loaded.
   */
  protected loadData(): Promise<any> {
    this.log.warn("loadData() was called but has not been implemented");
    return Promise.resolve();
  }

  /**
   * Sets the current status of the Store
   */
  @action
  protected setStatus(status: StoreStatus) {
    this._status = status;
  }
}
