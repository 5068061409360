import { observable } from "mobx";

/**
 * Describes the various states that an Entity may be in.
 *
 * @readonly
 * @enum {number}
 */
export enum EntityStatus {
  /** Entity has not yet been initialized */
  Uninitialized,
  /** Entity is being modified - current values may be stale */
  Updating,
  /** Entity is up-to-date and ready to be consumed */
  Ready,
  /** There was an error updating the Entity's properties */
  Error,
  /** The Entity has been deleted */
  Deleted
}

/** The set of possible actions that may occur on an Entity */
export type EntityUpdateAction = "Created" | "Updated" | "Deleted" | "Bulk";

/**
 * Represents a change to an Entity
 * @public
 */
export interface EntityUpdate<TEntity = any> {
  /** The action that has occured on the Entity (Created, Updated, Deleted, or Bulk) */
  action: EntityUpdateAction;
  /** The updated Entity or action metadata */
  data: TEntity;
  /** The type name of the Entity being acted upon (e.g. `Contact`) */
  entityName: string;
}

/** Represents a collection of Entity changes
 * (presumably all on the same Entity, but not necessarily)
 *
 * @public
 */
export interface BulkEntityUpdate<TEntity = any> extends EntityUpdate<EntityUpdate<TEntity>[]> {
  action: "Bulk";
  data: EntityUpdate<TEntity>[];
  entityName: string;
}

/**
 * Represents an object with data to be assigned to an Entity
 *
 * @public
 */
export interface EntitySource {
  $status?: EntityStatus;
  [prop: string]: any;
}

const DefaultEntityStatus = EntityStatus.Uninitialized;

/**
 * Base class with helpers for domain entities.
 *
 * NOTE: This is mostly just to provide a default constructor
 *       that accepts a source object (e.g. DTO) to map from.
 *       An object does not _need_ to derive from this class
 *       in order to be considered an "Entity".
 *
 * @public
 */
export abstract class Entity<TThunks = {}> {
  /**
   * The Entity's unique identifier
   *
   * @public
   */
  id?;

  /**
   * The status of the Entity (e.g. Updating, Loaded, Error, etc.)
   *
   * _NOTE: `$` prefix is meant to avoid conflicts with a real entity property;
   * the usage of `$` is somewhat arbitrary and TBD - can be or `_ or some other unique character(s)_
   *
   * @public
   */
  @observable $status = DefaultEntityStatus;

  protected thunks: TThunks;

  /** @public */
  constructor(
    /** (optional) object to copy data from */
    source?: EntitySource,
    /** (optional) object containing thunks to be used in lazy-loading scenarios */
    thunks?: TThunks
  ) {
    this.$assign(source, thunks);
  }

  /**
   * Mimics `Object.assign` to copy data from source object to this instance.
   *
   * @public
   */
  // tslint:disable-next-line:function-name
  $assign(
    /** (optional) object to copy data from */
    source?: EntitySource,
    /** (optional) object containing thunks to be used in lazy-loading scenarios */
    thunks?: TThunks
  ) {
    if (source == null) {
      this.$status = EntityStatus.Error;
      return;
    }

    try {
      Object.assign(this, source);
      this.thunks = Object.assign(this.thunks || {}, thunks);

      if (source.$status == null) {
        this.$status = EntityStatus.Ready;
      }
    } catch (ex) {
      this.$status = EntityStatus.Error;
    }
  }
}
