define(["framework/globalUtils/koNodePreprocessor", "app/util/user"], function(widgets, { default: user }) {
  const { addElementHandler, mapAttributes } = widgets;

  addElementHandler({
    type: "cr-symbol",
    process($node) {
      let path = $node.attr("path");
      let $dest = mapAttributes($node, $("<span />"));
      let text = user.parseSymbol(path);

      if ($node.is("[lower]")) {
        text = text.toLowerCase();
      }

      if ($node.is("[upper]")) {
        text = text.toUpperCase();
      }

      $dest.text(text);
      return $dest;
    }
  });
});
