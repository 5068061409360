(function() {
  //webpack adaptation

  ko.bindingHandlers.bindToWP = {
    init: function(element, valueAccessor, allBindings, viewModel, bindingContext) {
      var unwrap = ko.utils.unwrapObservable(valueAccessor());
      bindToWP(element, unwrap, allBindings, viewModel, bindingContext);

      ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
        $.each($.data(element), function(name, obj) {
          if (/Vm$/.test(name) && typeof obj.dispose === "function") {
            try {
              obj.dispose();
            } catch (e) {}
          }
        });
        element = null;
      });
    },
    update: function(element, valueAccessor, allBindings, viewModel, bindingContext) {
      var unwrap = ko.utils.unwrapObservable(valueAccessor());

      ko.ignoreDependencies(function() {
        bindToWP(element, unwrap, allBindings, viewModel, bindingContext);
      });
    }
  };

  //Loads an html snippet, then binds it to the viewmodel returned from the specified script element
  function bindToWP(element, unwrappedValue, allBindingsAccessor, viewModel, bindingContext) {
    var $element = $(element);
    //already loading this?
    if ($element.data("contentLoading") === unwrappedValue) return;
    if (!$element.data("cr-bindto-html-packets")) $element.data("cr-bindto-html-packets", []);

    var options = allBindingsAccessor().loadSettings,
      vmLoader = options.vm,
      htmlLoader = options.view,
      addToVmAs = ko.unwrap(options.addToVmAs);

    if (typeof options !== "object") throw "No load options specified in binding";

    if (!unwrappedValue) {
      deActivateCurrentViewModelIfNeeded(options);
      cr.domUtil.clearElement(element);
      $element.data("current-view-model-path", "");
      return;
    } else if ($element.data("current-view-model-path") == unwrappedValue) {
      return;
    } else {
      //is it cached?
      if ($element.data(unwrappedValue)) {
        deActivateCurrentViewModelIfNeeded(options);
        $element.children().detach();
        $element.append($element.data(unwrappedValue));
        var vmToActivate = $element.data(unwrappedValue + "-vm");
        setCurrentViewModel(vmToActivate, unwrappedValue);
        if (!options.suppressActivationAndDeActivation && vmToActivate && vmToActivate.activateViewModel) {
          vmToActivate.activateViewModel();
        }
      } else {
        //ko bindings fire aggressively - prevent the callbacks from firing multiple times
        $element.data("contentLoading", unwrappedValue);

        //set up the tear down stuff
        if (!$element.hasClass("cr-bindto-content")) {
          $element.addClass("cr-bindto-content");
          $element.data("cr-bindto-uninitialize", function() {
            $element.children().detach();
            $.each($element.data("cr-bindto-html-packets"), function(i, name) {
              $element.append($element.data(name));
              $element.data(name, null);
              $element.children().each(function() {
                ko.cleanNode(this);
              });
              $element.children().remove();
            });
            $element.data("cr-bindto-html-packets", []);
          });
        }
        //nope, load it!
        var loadingPieces = [unwrappedValue, htmlLoader()];
        if (vmLoader) {
          loadingPieces.push(vmLoader());
        }
        Promise.all(loadingPieces).then(([name, html, scriptItem]) => {
          //webpack wraps import()'s in a pseudo module, so account for that
          html = (html || {}).default || html;
          scriptItem = (scriptItem || {}).default || scriptItem;
          if (loadingPieces[0] != $(element).data("contentLoading")) {
            //quick requests come back out of order?
            return;
          }
          //make sure knockout hasn't killed this element off in the meantime
          if (!$element.data("cr-bindto-html-packets")) {
            return;
          }
          $(element).data("contentLoading", false);

          cr.domUtil.clearElement(element);

          element.innerHTML = html;

          $element.data(unwrappedValue, $(element).children());
          $element.data("cr-bindto-html-packets").push(unwrappedValue);

          var viewModelToBind, dynamicVm;

          if (typeof scriptItem === "function") {
            if (options.maintainContext) {
              dynamicVm = options.instantiateWith ? options.instantiateWith.call(viewModel, scriptItem) : new scriptItem(bindingContext);
              viewModelToBind = bindingContext.createChildContext(dynamicVm);
            } else {
              dynamicVm = options.instantiateWith ? options.instantiateWith.call(viewModel, scriptItem) : new scriptItem();
              viewModelToBind = dynamicVm;
            }

            if (addToVmAs) {
              viewModel[addToVmAs] = dynamicVm;
            }
          } else if (options.autoBind) {
            viewModelToBind = viewModel;
          }

          if (viewModelToBind) {
            ko.applyBindingsToDescendants(viewModelToBind, $element[0]);
          }

          if (options.onLoad) {
            var onLoadFunc = ko.utils.unwrapObservable(options.onLoad);
            typeof onLoadFunc === "function" && onLoadFunc(viewModelToBind);
          }

          deActivateCurrentViewModelIfNeeded(options);
          setCurrentViewModel(dynamicVm, unwrappedValue);

          //we do NOT call any activate dom on autoBind
          if (dynamicVm && typeof dynamicVm.activateDom === "function") {
            dynamicVm.activateDom();
          }

          //we do NOT call any activateViewModel on autoBind
          if (!options.suppressActivationAndDeActivation && dynamicVm && typeof dynamicVm.activateViewModel === "function") {
            dynamicVm.activateViewModel();
          }
          if (dynamicVm) {
            $element.data(unwrappedValue + "-vm", dynamicVm);
          }
        });
      }
    }

    function setCurrentViewModel(newVm, vmAddress) {
      $element.data("current-view-model", newVm);
      $element.data("current-view-model-path", vmAddress);
    }

    function deActivateCurrentViewModelIfNeeded(options) {
      if (options.suppressActivationAndDeActivation) return;
      var vmToDeactivate = $element.data("current-view-model");
      if (vmToDeactivate && typeof vmToDeactivate.deActivateViewModel === "function") {
        vmToDeactivate.deActivateViewModel();
      }
      $element.data("current-view-model", null);
    }
  }
})();

ko.bindingHandlers.loadWp = {
  init: function(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
    var unwrap = ko.utils.unwrapObservable(valueAccessor());
    loadWp(element, unwrap, allBindingsAccessor, viewModel);
  },
  update: function(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
    var unwrap = ko.utils.unwrapObservable(valueAccessor());
    loadWp(element, unwrap, allBindingsAccessor, viewModel);
  }
};
function loadWp(element, unwrappedValue, allBindingsAccessor, viewModel) {
  //whatever content this thing wants is taken care of.  We're done.
  if ($(element).attr("data-content-handled")) return;

  //var unwrappedValue = ko.utils.unwrapObservable(valueAccessor());
  var options = allBindingsAccessor().loadSettings;

  if (typeof options !== "object") throw "No load options specified in binding";
  if (typeof options.html !== "function") throw "Must specify html";

  if (!unwrappedValue) return;

  var requirements = [options.html()];
  if (options.script) requirements.push(options.script());

  Promise.all(requirements).then(([html, scriptItem]) => {
    if (html.default) {
      html = html.default;
    }
    if (scriptItem && scriptItem.default) {
      scriptItem = scriptItem.default;
    }
    element.innerHTML = html;

    if (options.autoBind) {
      ko.applyBindingsToDescendants(viewModel, element);
    }
    typeof options.onLoad === "function" && options.onLoad(scriptItem, element);
  });

  $(element).attr("data-content-handled", "handled");
}
