/**
 * This is the entry point or index.js for the CentralReach application.
 * Initializes and configure resources that are required to bootstrap the application.
 */

import configureNetworking from "app/initialization/configureNetworking";
import initializeCRFramework from "app/initialization/initializeCRFramework";
import initializeGlobaljQueryHelpers from "app/initialization/initializeGlobalJQueryHelpers";
import initializeKnockout from "app/initialization/initializeKnockout";
import initializeUser from "app/initialization/initializeUser";
import { initializeHistoryListening } from "app/util/historyListenerStore";
import { getCSRFToken, hasCSRFToken, reloadForCSRFToken } from "./app/util/apiTransport";
import { loadEksApiRoutes } from "./app/util/eks";
import { pollForEksRouteChanges } from "./app/util/eksService";

(async function initializeApplication() {
  /**
   * Initialize various global jquery "hacks"
   */
  initializeGlobaljQueryHelpers();

  /**
   * initialize and configure knockout for the rest of the application.
   */
  initializeKnockout(ko);

  /**
   * includes required CR framework dependencies and sets a global variable called cr which is used
   * in various places around the application.
   */
  initializeCRFramework();

  /**
   * Configures CR transport
   */
  configureNetworking();

  /**
   * pre-load csrf token and eks routes
   */
  await Promise.all([loadEksApiRoutes(), getCSRFToken(0)]);

  /**
   * if we still don't have a csrf token, reload the page once
   * due to a bug in iOS 12 with SameSite=None cookies
   */
  if (!hasCSRFToken()) {
    reloadForCSRFToken();
  }

  // make sure we have a valid user object (even if it's an anonymous user)
  // and handle any redirects or impersonation
  const result = await initializeUser();

  // stop loading the app in case of special auth processing (see auth silent callback)
  if (result === "break") {
    return;
  }

  // check to make sure we're on the latest version of the app
  await import("app/initialization/initializeAutoUpgrade").then(m => m.default());

  await import("app/initialization/initializePendo").then(m => m.default());

  /**
   * Create the app shell (header, login, etc..)
   */
  import("./App").then(({ default: App }) => {
    // Some history changes take place outside of react (i.e. auth routes), listen for them here
    initializeHistoryListening();
    pollForEksRouteChanges();
    App.renderDOM();
  });
})();
