import {cleanHtml} from "../../app/util/htmlHelpers";

(function() {
  ko.bindingHandlers.textLower = {
    update: function(element, valueAccessor) {
      var text = ko.unwrap(valueAccessor());
      $(element).text(text ? String(text).toLowerCase() : text);
    }
  };

  const evalFunctionWithDefaults = new Function(
    "$context",
    "$data",
    "bindingString",
    "defaults",
    `
    with($context){
        with($data){
            return Object.assign({}, eval(defaults), eval(bindingString));
        }
    }
`
  );

  ko.bindingHandlers.delegatedHashChange = {
    init: function(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
      $(element).on("click", "[data-hash]", function() {
        var bindingString = "({" + $(this).attr("data-hash") + "})",
          context = ko.contextFor(this),
          vm = context.$data,
          ab = allBindingsAccessor(),
          defaults = "({" + (ab.hashDefaults || "") + "})";

        let result = evalFunctionWithDefaults(context, vm, bindingString, defaults);
        processHashChange(result);
      });
    }
  };

  const evalFunction = new Function(
    "$context",
    "$data",
    "bindingString",
    "defaults",
    `
    with($context){
        with($data){
            return eval(bindingString);
        }
    }
`
  );

  ko.bindingHandlers.hashChange = {
    init: function(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
      $(element).on("click", function() {
        var bindingString = "({" + $(this).attr("data-hash") + "})",
          context = ko.contextFor(this),
          vm = context.$data;

        let result = evalFunction(context, vm, bindingString);
        processHashChange(result);
      });
    }
  };

  const evalFunctionForEnterKey = new Function(
    "$context",
    "$data",
    "$valueObject",
    "bindingString",
    `
    with($context){
        with($data){
            with($valueObject){
                return eval(bindingString);
            }
        }
    }
`
  );

  ko.bindingHandlers.enterKeyHashChange = {
    init: function(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
      $(element).on("keypress", function(e) {
        if (e.which != 13) return;

        var bindingString = "({" + ko.unwrap(valueAccessor()) + "})",
          context = ko.contextFor(this),
          vm = context.$data;

        let result = evalFunctionForEnterKey(context, vm, { $value: element.value || null }, bindingString);
        processHashChange(result);
      });
    }
  };

  function processHashChange(result) {
    for (var key in result) {
      if (result.hasOwnProperty(key) && typeof result[key] === "function") {
        result[key] = result[key]();
      }
    }
    cr.globalHashManager.applyTheseHashChanges(
      $.map(result, function(value, key) {
        return { key: key, value: value };
      })
    );
  }

  // trackChanges binding
  //
  // extends a single observable with the ability to check whether
  // the value has changed from its originally assigned value
  //
  // sample usage:
  // var val = ko.observable('a');
  // val.trackChanges();

  // <div data-bind="visible: val.isChanged">CHANGED</div>
  // <select data-bind="value: val">
  //    <option>a</option>
  //    <option>b</option>
  //    <option>c</option>
  // </select>
  ko.observable.fn.trackChanges = function() {
    this.isChanged = ko.observable(false);
    var originalValue = this();

    this.subscribe(
      function(val) {
        this.isChanged(val != originalValue);
      }.bind(this)
    );
  };

  // disableDuring binding
  //
  // Disables all input, textarea, select, button, or select2 elements inside the element on which
  // you bind it (except those with the class "no-disable-during") when an observable becomes
  // truthy, and restores them back to their previous state when it becomes falsey.
  //
  // Fields with the class "no-disable-during" are exempted from processing. The check is
  // made both during the disabling and enabling phases; if you want to prevent a single field
  // from being restored, add the class before changing the observable back. (Removing the
  // class while things are disabled has no effect, because we leave the field alone if we
  // didn't store its previous state when disabling.)
  //
  // You can specify an optional additional disableDuringCallback binding which will get
  // called twice when the contents are being re-enabled (this is one callback for the
  // entire set, there is no per-element callback): The first call is before the elements
  // are re-enabled: It's called with the view model as `this` and two arguments: a jQuery
  // wrapper around the element this binding is on, and the string "before". The second call
  // is after the elements have had their previous state restored (same call but the string
  // is "after"). The "before" call can return `false` to prevent the binding updating the
  // elements. The return value of an "after" call is completely ignored.
  ko.bindingHandlers.disableDuring = {
    update: function disableDuring_update(element, valueAccessor, allBindingsAccessor, viewModel) {
      var value = ko.unwrap(valueAccessor()),
        callback,
        $el = $(element),
        disabled = $el.data("disableDuring"),
        disabling = value && !disabled,
        enabling = !value && disabled,
        fields;
      if (disabling || enabling) {
        $el.data("disableDuring", !disabled);
        fields = $el.find("input, textarea, select, button").not(".no-disable-during, .select2-focusser");
        if (disabling) {
          fields.each(function() {
            var $this = $(this);
            $this.data("disableDuring-disabled", $this.prop("disabled"));
            if (this.className.indexOf("select2") !== -1) {
              // This input is part of a select2 instance, don't change its
              // property directly, use select2
              $this.select2("enable", false);
            } else {
              // Normal case
              $this.prop("disabled", true);
            }
          });
        } else {
          callback = allBindingsAccessor().disableDuringCallback;
          if (typeof callback === "function" && callback.call(viewModel, $el, "before") === false) {
            // Caller told us to leave it
            return;
          }
          fields.each(function() {
            var $this = $(this),
              flag = $this.data("disableDuring-disabled");
            // Leave fields we didn't see when disabling alone when enabling
            if (typeof flag !== "undefined") {
              $this.removeData("disableDuring-disabled");
              if (this.className.indexOf("select2") !== -1) {
                // This input is part of a select2 instance, don't change its
                // property directly, use select2
                $this.select2("enable", !flag);
              } else {
                // Normal case
                $this.prop("disabled", flag);
              }
            }
          });
          if (typeof callback === "function") {
            callback.call(viewModel, $el, "after");
          }
        }
      }
    }
  };

  // disableWhen binding
  //
  // Disables all input, textarea, select, button, or select2 elements inside the element on which
  // you bind (except those with the class "no-disable-when") when an observable becomes
  // truthy, and restores them back to their previous state when it becomes falsey.
  //
  // Fields with the class "no-disable-when" are exempted from processing. The check is
  // made both during the disabling and enabling phases;
  //
  //specify dependsOn as one observable, or an array in binding string to force update to re-run when observable(s) change
  ko.bindingHandlers.disableWhen = {
    update: function disableDuring_update(element, valueAccessor, allBindingsAccessor, viewModel) {
      var disabling = ko.unwrap(valueAccessor()),
        $el = $(element),
        ab = allBindingsAccessor(),
        targets = $(),
        widgetsNeedingDisabledAttr = ["cr-time-input", "cr-select2"],
        filterString = "input, textarea, select, button, " + widgetsNeedingDisabledAttr.join(", ");

      //we're modifying dom elements, so we may need to re-process if some other value changes (since the dom elements will be re-rendered)
      if (ab.dependsOn) {
        var dependencies = $.isArray(ab.dependsOn) ? ab.dependsOn : [ab.dependsOn];
        dependencies.forEach(function(dependency) {
          ko.unwrap(dependency);
        });
      }

      //used with a virtual binding
      if (element.nodeType == 8) {
        var child = ko.virtualElements.firstChild(element);
        while (child) {
          targets = targets.add(child);
          child = ko.virtualElements.nextSibling(child);
        }
        targets = targets
          .find(filterString)
          .addBack(filterString)
          .not(".no-disable-when, .select2-focusser");
      } else {
        targets = $el.find(filterString).not(".no-disable-when, .select2-focusser");
      }

      targets.each(function() {
        processNode.call(this, disabling);
      });

      function processNode(disabled) {
        var $this = $(this);
        if (this.className.indexOf("select2") !== -1) {
          // This input is part of a select2 instance, don't change its property directly, use select2
          $this.select2("enable", !disabled);
        } else {
          if (widgetsNeedingDisabledAttr.indexOf(this.tagName.toLowerCase()) >= 0) {
            if (disabled) {
              $this.attr("disabled", true);
            }
          } else {
            $this.prop("disabled", disabled);
          }
        }
      }
    }
  };
  ko.virtualElements.allowedBindings.disableWhen = true;

  ko.bindingHandlers.renderSvg = {
    init: renderSvg,
    update: renderSvg
  };

  function renderSvg(element, valueAccessor, allBindingsAccessor, viewModel) {
    var rawVal = valueAccessor();
    var svgText = ko.utils.unwrapObservable(rawVal);

    if (!svgText) {
      element.innerHTML = "";
    } else {
      element.innerHTML = "";

      if (svgText.indexOf("<svg") > 0) {
        svgText = svgText.substr(svgText.indexOf("<svg"));
      }

      var $svg = $(svgText),
        width = +$svg.attr("width").replace("px", ""),
        height = +$svg.attr("height").replace("px", ""),
        ar = width / height,
        maxHeight = +allBindingsAccessor().maxSvgHeight;

      if (maxHeight && height > maxHeight) {
        $svg[0].setAttribute("viewBox", "0 0 " + width + " " + height);
        $svg.attr("width", maxHeight * ar).attr("height", maxHeight);
      }
      $(element).append($svg[0]);
    }
  }

  ko.bindingHandlers.sortHeaders = {
    init: function(element, valueAccessor, allBindingsAccessor, listVm) {
      var sortCol = ko.observable(""),
        sortAsc = ko.observable(true),
        options = valueAccessor(),
        obsArr = options.arr,
        sorting = false;

      var sub = obsArr.subscribe(function(arr) {
        if (sorting || !sortCol()) return;

        sorting = true;
        sortObservableArray();
        sorting = false;
      });

      ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
        sub.dispose();
        sub = null; //shouldn't need, but I think I've seen things retained by not
      });

      $("a[data-field]", element).after('<span class="fa fa-fw"></span>');

      $(element).on("click", "a[data-field]", function() {
        var field = $(this).attr("data-field");
        if (sortCol() == field) {
          sortAsc(!sortAsc());
        } else {
          sortCol(field);
          sortAsc(true);
        }

        $("a[data-field] + span", element).removeClass("fa-angle-up fa-angle-down");
        $('a[data-field="' + field + '"] + span', element).addClass(sortAsc() ? "fa-angle-up" : "fa-angle-down");

        sortObservableArray();
      });

      function sortFunc(el) {
        var val = typeof el[sortCol()] == "function" ? el[sortCol()]() : el[sortCol()];
        return typeof val === "string" ? val.toLowerCase() : val;
      }

      function sortObservableArray() {
        var sorted = Linq.From(obsArr())["OrderBy" + (sortAsc() ? "" : "Descending")](sortFunc);
        obsArr(sorted.ToArray());
      }
    }
  };

  ko.bindingHandlers.executeOnEnter = {
    init: function(element, valueAccessor, allBindingsAccessor, viewModel) {
      var boundTo = valueAccessor();

      $(element).keypress(function(evt) {
        var keyCode = evt.which || evt.keyCode;
        if (keyCode === 13) {
          //we won't fire this request if it's already running.
          var btn = cr.domUtil.getButtonFor(evt.currentTarget);
          if (btn && $(btn).prop("disabled")) return;
          boundTo.call(viewModel, viewModel, $.extend({}, evt, { target: btn || evt.target }));
        }
      });
    }
  };

  ko.bindingHandlers.readmore = {
    update: function(element, valueAccessor) {
      var content = ko.unwrap(valueAccessor());
      element.innerHTML = cleanHtml(content);
      $(element).readmore(); // function to turn long text into abbreviated with a "read more" link
    }
  };

  ko.bindingHandlers.readmoreShort = {
    update: function(element, valueAccessor) {
      var content = ko.unwrap(valueAccessor());
      element.innerHTML = cleanHtml(content);
      $(element).readmore({ substr_len: 25 }); // function to turn long text into abbreviated with a "read more" link
    }
  };

  ko.bindingHandlers.phone = {
    init: phone,
    update: phone
  };

  function phone(element, valueAccessor, allBindingsAccessor, viewModel) {
    var rawVal = valueAccessor();
    var unwrappedValue = ko.utils.unwrapObservable(rawVal);

    if (!unwrappedValue) {
      element.innerHTML = "";
    } else {
      if (unwrappedValue.length >= 10) {
        element.innerHTML = "(" + unwrappedValue.substring(0, 3) + ") " + unwrappedValue.substring(3, 6) + "-" + unwrappedValue.substring(6, 10);
        element.innerHTML += unwrappedValue.length > 10 ? " Ext. " + unwrappedValue.substring(10, unwrappedValue.length) : "";
      } else {
        element.innerHTML = unwrappedValue;
      }
    }
  }

  ko.bindingHandlers.timeago = {
    init: timeago,
    update: timeago
  };

  function timeago(element, valueAccessor, allBindingsAccessor, viewModel) {
    var rawVal = valueAccessor();
    var unwrappedValue = ko.utils.unwrapObservable(rawVal);

    if (!unwrappedValue) {
      element.innerHTML = "";
    } else {
      element.innerHTML = $.timeago(unwrappedValue);
    }
  }

  ko.bindingHandlers.timeFromNow = {
    init: timeFromNow,
    update: timeFromNow
  };

  function timeFromNow(element, valueAccessor, allBindingsAccessor, viewModel) {
    var rawVal = valueAccessor();
    var unwrappedValue = ko.utils.unwrapObservable(rawVal);

    if (!unwrappedValue) {
      element.innerHTML = "";
    } else {
      element.innerHTML = moment(unwrappedValue).fromNow();
    }
  }

  ko.bindingHandlers.secondsToTimeOfDay = {
    init: secondsToTimeOfDay,
    update: secondsToTimeOfDay
  };

  function secondsToTimeOfDay(element, valueAccessor, allBindingsAccessor, viewModel) {
    var unwrappedValue = ko.utils.unwrapObservable(valueAccessor());

    var dt;
    dt = new Date();
    dt.setHours(Math.floor(unwrappedValue / 60 / 60));
    dt.setMinutes(Math.floor(unwrappedValue / 60) % 60);

    element.innerHTML = dt.toString("h:mm tt");
  }

  // Just like secondsToTimeOfDay, but without the space, removing :00 if present, and in lower case
  ko.bindingHandlers.secondsToTimeOfDayShort = {
    update: secondsToTimeOfDayShort
  };

  function secondsToTimeOfDayShort(element, valueAccessor, allBindingsAccessor, viewModel) {
    var unwrappedValue = ko.utils.unwrapObservable(valueAccessor());

    var dt;
    dt = new Date();
    dt.setHours(Math.floor(unwrappedValue / 60 / 60));
    dt.setMinutes(Math.floor(unwrappedValue / 60) % 60);

    element.innerHTML = dt
      .toString("h:mmtt")
      .replace(/:00/g, "")
      .toLowerCase();
  }

  ko.bindingHandlers.minutesToTimeOfDay = {
    init: minutesToTimeOfDay,
    update: minutesToTimeOfDay
  };

  function minutesToTimeOfDay(element, valueAccessor, allBindingsAccessor, viewModel) {
    var unwrappedValue = ko.utils.unwrapObservable(valueAccessor());

    var dt;
    dt = new Date();
    dt.setHours(Math.floor(unwrappedValue / 60));
    dt.setMinutes(Math.floor(unwrappedValue % 60));

    element.innerHTML = dt.toString("h:mm tt");
  }

  // Just like minutesToTimeOfDay, but without the space, removing :00 if present, and in lower case
  ko.bindingHandlers.minutesToTimeOfDayShort = {
    update: minutesToTimeOfDayShort
  };

  function minutesToTimeOfDayShort(element, valueAccessor, allBindingsAccessor, viewModel) {
    var unwrappedValue = ko.utils.unwrapObservable(valueAccessor());

    var dt;
    dt = new Date();
    dt.setHours(Math.floor(unwrappedValue / 60));
    dt.setMinutes(Math.floor(unwrappedValue % 60));

    element.innerHTML = dt
      .toString("h:mmtt")
      .replace(/:00/g, "")
      .toLowerCase();
  }

  // == minutesToDuration and minutesToDurationShort

  ko.bindingHandlers.minutesToDuration = {
    update: function minutesToDuration_update(element, valueAccessor) {
      element.innerHTML = formatMinutesToDuration(ko.unwrap(valueAccessor())) || "Unknown";
    }
  };
  ko.bindingHandlers.minutesToDurationShort = {
    update: function minutesToDurationShort_update(element, valueAccessor) {
      var text = formatMinutesToDuration(ko.unwrap(valueAccessor())) || "Unknown";
      element.innerHTML = text
        .replace(/hour/g, "hr")
        .replace(/Hour/g, "Hr")
        .replace(/minute/g, "min")
        .replace(/Minute/g, "Min");
    }
  };

  // Make the value of an input a text-based duration of minutes, linked to a
  // numeric observable.
  // Ex: <input type="text" data-bind="valueAsLengthInMinutes: length">
  ko.bindingHandlers.valueAsLengthInMinutes = {
    init: function valueAsLengthInMinutes_init(element, valueAccessor) {
      var $el = $(element),
        value = valueAccessor();

      $el
        .on("change.valueAsLengthInMinutes", function() {
          var length = CRParseDuration($el.val() || 0);
          if (value() === length) {
            // Same value, just a text change: Format it
            $el.val(formatMinutesToDuration(valueAccessor()()));
          } else {
            // Different value: Set it (which will format it)
            value(length);
          }
        })
        .val(formatMinutesToDuration(value()));
    },
    update: function valueAsLengthInMinutes_init(element, valueAccessor) {
      $(element).val(formatMinutesToDuration(valueAccessor()()));
    }
  };

  // Format the given number of minutes into a duration string like "1 minute"
  // or "1:30 hours"; returns "" if the value can't be coerced to a number or
  // is NaN. Any fractional portion is truncated.
  // If `useFractions` is truthy, we do things like "1.5 hours" rather than "1:30 hours",
  // up to two digits of precision.
  // Used by multiple handlers.
  // NOTE: `useFractions` is NOT THOROUGHLY TESTED. Note that things like 145 end up
  // being "2.42 hours", which is why we lean toward the : notation (2:25 hours)
  function formatMinutesToDuration(value, useFractions) {
    var hours, minutes, isNegative, rv;

    value = +value; // Force to number

    if (value < 0) {
      isNegative = true;
      value = value * -1;
    }

    if (isNaN(value)) {
      rv = "";
    } else {
      value = value | 0; // | = bitwise = truncate fractional portion
      if (value == 1) {
        rv = "1 minute";
      } else if (value < 60) {
        rv = String(value) + " minutes";
      } else if (value == 60) {
        rv = "1 hour";
      } else {
        if (useFractions) {
          if (value % 60 === 0) {
            // No fractional portion
            rv = value / 60 + " hours";
          } else {
            // Up to two digits, but remove trailing zeros
            rv = (value / 60).toFixed(2).replace(/0+$/, "") + " hours";
          }
        } else {
          hours = Math.floor(value / 60);
          minutes = value % 60;
          rv = String(hours) + ":" + String(minutes).padLeft(2) + " hours";
        }
      }
    }
    return isNegative ? "-" + rv : rv;
  }

  //    ko.bindingHandlers.numeric = {
  //        init: updateElementWithNumeric.bind(null, { emptyStringAs: 0, symbol: '' }),
  //        update: updateElementWithNumeric.bind(null, { emptyStringAs: 0, symbol: '' })
  //    };

  ko.bindingHandlers.currency = {
    init: function(element, valueAccessor, allBindingsAccessor) {
      updateElementWithNumeric({ emptyStringAs: 0, symbol: "$" }, element, valueAccessor, allBindingsAccessor);
    },
    update: function(element, valueAccessor, allBindingsAccessor) {
      updateElementWithNumeric({ emptyStringAs: 0, symbol: "$" }, element, valueAccessor, allBindingsAccessor);
    }
  };

  ko.bindingHandlers.emptyCurrency = {
    init: function(element, valueAccessor, allBindingsAccessor) {
      if (ko.utils.unwrapObservable(valueAccessor()) === "") {
        element.innerHTML = "";
      } else {
        updateElementWithNumeric({ emptyStringAs: 0, symbol: "$" }, element, valueAccessor, allBindingsAccessor);
      }
    },
    update: function(element, valueAccessor, allBindingsAccessor) {
      if (ko.utils.unwrapObservable(valueAccessor()) === "") {
        element.innerHTML = "";
      } else {
        updateElementWithNumeric({ emptyStringAs: 0, symbol: "$" }, element, valueAccessor, allBindingsAccessor);
      }
    }
  };

  ko.bindingHandlers.numeric = {
    init: function(element, valueAccessor, allBindingsAccessor) {
      updateElementWithNumeric({ emptyStringAs: 0, symbol: "" }, element, valueAccessor, allBindingsAccessor);
    },
    update: function(element, valueAccessor, allBindingsAccessor) {
      updateElementWithNumeric({ emptyStringAs: 0, symbol: "" }, element, valueAccessor, allBindingsAccessor);
    }
  };

  ko.bindingHandlers.wholeNumeric = {
    update: function(element, valueAccessor, allBindingsAccessor) {
      updateElementWithNumeric({ emptyStringAs: 0, symbol: "", roundToDecimalPlace: 0 }, element, valueAccessor, allBindingsAccessor);
    }
  };

  ko.bindingHandlers.numberInputOnly = {
    init: function(element, valueAccessor) {
      $(element).on("paste", e => {
        e.stopPropagation();
        e.preventDefault();

        const el = $(element);
        if (!el) {
          return;
        }

        // Get pasted data via clipboard API
        const clipboardData = e.originalEvent.clipboardData || window.clipboardData;
        const pastedData = (clipboardData && clipboardData.getData("Text")) || "";
        // strip out all non-digits
        const newValue = pastedData.replace(/\D/g, "");
        // respect the max if set
        let maxLength = Number.parseInt(el.attr("maxlength"));
        maxLength = Number.isNaN(maxLength) ? newValue.length : maxLength;
        // update element value with pasted
        $(element)
          .val(newValue.substring(0, maxLength))
          .change();
      });

      $(element).on("keydown", function(e) {
        // Allow: backspace, delete, tab, escape, and enter
        if (
          e.keyCode == 46 ||
          e.keyCode == 8 ||
          e.keyCode == 9 ||
          e.keyCode == 27 ||
          e.keyCode == 13 ||
          // Allow: Ctrl+A or CMD+A
          ((e.keyCode == 65 && e.ctrlKey === true) || e.metaKey === true) ||
          // Allow: Ctrl+V or CMD+V
          (e.keyCode == 86 && (e.ctrlKey === true || e.metaKey === true)) ||
          // Allow: home, end, left, right
          (e.keyCode >= 35 && e.keyCode <= 39)
        ) {
          // let it happen, don't do anything
          return;
        } else {
          // Ensure that it is a number and stop the keypress
          if (e.shiftKey || ((e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105))) {
            e.preventDefault();
          }
        }
      });
    }
  };
  ko.bindingHandlers.byteSize = {
    init: byteSize,
    update: byteSize
  };
  function byteSize(element, valueAccessor) {
    $(element).text(CRFormatBytes(ko.unwrap(valueAccessor())));
  }

  function updateElementWithNumeric(defaultOptions, element, valueAccessor, allBindingsAccessor, viewModel) {
    var targetValue,
      currentValue = ko.utils.unwrapObservable(valueAccessor());

    var options = $.extend(defaultOptions, allBindingsAccessor().displayOptions || {});

    // check if we have an optional display argument called "zeroAs" which indicates
    // that we want to treat zero-number differently and instead display text or an empty string
    if (options.zeroAs && currentValue === 0) {
      element.innerHTML = options.zeroAs;
    } else {
      targetValue = firstNumeric(currentValue, +currentValue, options.emptyStringAs);
      if (typeof targetValue !== "number") {
        //user is likely setting value to empty string.  So be it - set it and exit.
        element.innerHTML = targetValue;
      } else {
        element.innerHTML = targetValue.formatCurrency(options);
      }
    }
  }

  function firstNumeric() {
    for (var i = 0; i < arguments.length; i++) {
      if (typeof arguments[i] === "number" || i === arguments.length - 1) {
        return arguments[i];
      }
    }
  }

  ko.bindingHandlers.autoComplete = {
    init: function(element, valueAccessor) {
      autoComplete(element, valueAccessor);
    },
    update: function(element, valueAccessor) {
      autoComplete(element, valueAccessor);
    }
  };

  function autoComplete(element, valueAccessor) {
    var options = ko.utils.unwrapObservable(valueAccessor());

    cr.createAutoCompleter(options);
  }

  //knockout's html binding runs scripts in the html, which can be a problem.  That's why we have this
  ko.bindingHandlers.innerHTML = {
    update: function(element, valueAccessor) {
      element.innerHTML = ko.unwrap(valueAccessor());
    }
  };

  ko.bindingHandlers.jSignature = {
    init: function(element, valueAccessor, allBindingsAccessor) {
      var observable = ko.unwrap(valueAccessor)();
      var $el = $(element);

      setTimeout(function() {
        $el.jSignature();

        $el.on("change", function() {
          // guard against accidental overrides of existing values with nothing
          let sigData = $(this).jSignature("getData", "base30")[1],
            sigChars = [...new Set(sigData.split("").map(c => c))];
          if (sigChars.length == 2 && sigChars.includes("0") && sigChars.includes("_")) return;

          observable(sigData);
        });
      }, 500);
    },
    update: function(element, valueAccessor) {
      var $el = $(element);
      var observable = ko.unwrap(valueAccessor)();
      if (!observable()) {
        $el.jSignature("clear");
      }
    }
  };

  ko.bindingHandlers.minutesAsHoursFraction = {
    init: minutesAsHoursFraction,
    update: minutesAsHoursFraction
  };
  function minutesAsHoursFraction(element, valueAccessor) {
    var minutes = ko.unwrap(valueAccessor());
    element.innerHTML = (+minutes / 60).toFixed(2);
  }

  ko.extenders.parseTime = function(target, userOptions) {
    // The step is the nearest block of minutes that a value gets rounded to ie: step = 5 user enters 142 output value is 1:40

    var defaultOptions = { format: "h:mm tt", step: 1 };
    var options = $.extend(defaultOptions, userOptions || {});

    var result = ko
      .computed({
        read: target, //always return the original observables value
        write: function(newValue) {
          var current = target(),
            valueToWrite = CRParseTime(newValue, options.step, options.format);

          //only write if it changed
          if (valueToWrite !== current) {
            target(valueToWrite);
          } else {
            //if the parsed value is the same, but a different value was written, force a notification for the current field
            if (newValue !== current) {
              target.notifySubscribers(valueToWrite);
            }
          }
        }
      })
      .extend({ notify: "always" });

    return result;
  };

  ko.extenders.easyPhone = function(target) {
    var area = ko.observable("");
    var prefix = ko.observable("");
    var lineNumber = ko.observable("");

    target.subscribe(function(newPhoneValue) {
      // Reset the values if we have a blank phone number
      if (newPhoneValue.length === 0) {
        area("");
        prefix("");
        lineNumber("");
      }

      // Calculate the new splits if we are given a valid 10 digit phone number
      // Otherwise use the existing split values.
      area(newPhoneValue.length !== 10 ? area() : newPhoneValue.substr(0, 3));
      prefix(newPhoneValue.length !== 10 ? prefix() : newPhoneValue.substr(3, 3));
      lineNumber(newPhoneValue.length !== 10 ? lineNumber() : newPhoneValue.substr(6, 4));
    });

    target.phoneArea = ko.computed({
      read: function() {
        return area();
      },
      write: function(value) {
        area(value);
        computeNewPhoneValue();
      },
      owner: target
    });

    target.phonePrefix = ko.computed({
      read: function() {
        return prefix();
      },
      write: function(value) {
        prefix(value);
        computeNewPhoneValue();
      },
      owner: target
    });
    target.phoneLineNumber = ko.computed({
      read: function() {
        return lineNumber();
      },
      write: function(value) {
        lineNumber(value);
        computeNewPhoneValue();
      },
      owner: target
    });

    function computeNewPhoneValue() {
      target("" + area() + prefix() + lineNumber());
    }

    return target;
  };

  // A "binding" that says: Don't bind anything below this in the tree.
  // (You can then bind specific parts of the things within yourself.)
  // So: <div data-bind="stopBinding: true"><span>This is not bound</span></div>
  // See: http://www.knockmeout.net/2012/05/quick-tip-skip-binding.html
  // Obviously avoid using this wherever possible, it suggests a structural problem.
  // Used primarily for hacks for partially-KO'ified modules.
  ko.bindingHandlers.stopBinding = {
    init: function() {
      return { controlsDescendantBindings: true };
    }
  };

  // only allow digits in an input field
  ko.bindingHandlers.digitValue = {
    init: digitValue,
    update: function(element, valueAccessor) {
      $(element).val(ko.unwrap(valueAccessor()));
    }
  };

  function digitValue(element, valueAccessor, allBindings, viewModel, bindingContext) {
    var $el = $(element);
    var value = valueAccessor();

    $el.val(ko.unwrap(value));

    ko.utils.registerEventHandler(element, "keydown", function(e) {
      if (e.shiftKey === true) {
        if (e.which == 9) {
          return true;
        }
        return false;
      }
      // only allow numbers or numpad
      if (e.which > 57 && !(e.which >= 96 && e.which <= 105)) {
        return false;
      }
      if (e.which == 32) {
        return false;
      }
      return true;
    });
    ko.utils.registerEventHandler(element, "change", function(e) {
      if (ko.isObservable(value)) value(+this.value);
    });
  }

  // Adds a slider and legend to the element
  //
  // The observable bound is the value for the slider.
  // Seperate options binding for max and min for the slider
  // provides some basic defaults, and will also be passed to the
  // slider function for any other options.
  //
  // See also `basicSlider` below for one that just creates the slider on the
  // element you're binding to
  ko.bindingHandlers.slider = {
    init: initSlider,
    update: slider
  };

  function initSlider(element, valueAccessor, allBindings, viewModel, bindingContext) {
    var value = valueAccessor();
    var unwrappedValue = ko.unwrap(value);
    var options = $.extend(
      {
        max: 10,
        min: 0,
        session: false
      },
      allBindings.get("sliderOptions")
    );

    var $el = $(element),
      $legend = $('<div class="ui-slider-legend">'),
      $slider = $('<div class="slider">').slider(options);
    $slider.slider("value", unwrappedValue === "" ? options.min : unwrappedValue);
    $el.append($slider);

    for (var i = options.min; i <= options.max; i++) {
      var $l = $('<p class="slider-numbers">').text(i);
      $l.addClass(options.session ? "slider-label-session" : "slider-label");
      $legend.append($l);
    }

    $el.append($legend);

    ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
      try {
        $slider.slider("destroy");
      } catch (ex) {}
    });
  }

  function slider(element, valueAccessor, allBindings, viewModel, bindingContext) {
    var value = valueAccessor(),
      unwrappedValue = ko.unwrap(value),
      sliderOptions = allBindings.get("sliderOptions"),
      slideStop = sliderOptions && sliderOptions.slideStop,
      context = (sliderOptions && sliderOptions.context) || viewModel,
      $slider = $(element).find(".slider");

    $slider.on("slidechange", function(event, ui) {
      if (ko.isObservable(value)) value(ui.value);
    });

    if (typeof slideStop === "function") {
      $slider.on("slidestop", (e, ui) => {
        slideStop.call(context, ui.value);
      });
    }

    $slider.slider("value", +unwrappedValue || 0);

    ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
      $slider.off("slidechange slidestop");
    });
  }

  // A *basic* slider that just does the slider, nothing else
  //
  // `simpleSlider`:
  //      The main binding: Must be an observable which will receive the value
  //      when the slider changes (user stops dragging it).
  //      If it's an array, the slider will have a handle for each element in the
  //      array.
  // `simpleSliderOptions`: Option so for the slider, can include:
  //      * Any jQuery UI slider option other than `change`, `slide`, `value`, or `values`
  //      * Our special `update` which can be an observable or just a plain function
  //        that gets updated when the slider is being dragged, before the user lets
  //        go (interim updates). If it's a plain function, it receives the new value
  //        followed by the event. If the slider has multiple handles, the value we
  //        update with is an array.
  var simpleSliderDefaults = {
    min: 0,
    max: 100
  };
  ko.bindingHandlers.simpleSlider = {
    init: function initSimpleSlider(element, valueAccessor, allBindingsAccessor) {
      var $el = $(element),
        value = valueAccessor(),
        unwrappedValue,
        isRange,
        options = $.extend({}, simpleSliderDefaults, allBindingsAccessor().simpleSliderOptions, {
          change: function(event, ui) {
            value(isRange ? ui.values.clone() : ui.value);
          }
        });

      if ("value" in options) {
        throw cr.createException("simpleSlider options cannot include 'value'");
      }
      if ("values" in options) {
        throw cr.createException("simpleSlider options cannot include 'values'");
      }
      if (!ko.isObservable(value)) {
        throw cr.createException("simpleSlider requires an observable");
      }

      unwrappedValue = value();
      isRange = Array.isArray(unwrappedValue);
      if (isRange) {
        options.values = unwrappedValue.clone();
      } else {
        options.value = unwrappedValue;
      }

      if (typeof options.update === "function") {
        // observable or otherwise
        options.slide = function(event, ui) {
          // Interim update, user dragging slider
          if (ko.isObservable()) {
            options.update(isRange ? ui.values.clone() : ui.value);
          } else {
            options.update(isRange ? ui.values.clone() : ui.value, event);
          }
        };
      } else if ("slide" in options) {
        throw cr.createException("simpleSlider options cannot include 'slide'");
      }
      $el.slider(options);

      ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
        try {
          $slider.slider("destroy");
        } catch (ex) {}
      });
    },
    update: function updateSimpleSlider(element, valueAccessor) {
      var $el = $(element),
        value = valueAccessor()();
      if (Array.isArray(value)) {
        value.forEach(function(val, index) {
          $el.slider("values", index, val);
        });
      } else {
        $el.slider("value", value);
      }
    }
  };

  // Sync the observable you extend (the "slave") to another observable (the "master"):
  //
  // 1. When you set up sync, it sets the value of `slave` to match `master`.
  // 2. When `master` changes, `slave` is updated to the new value.
  // 3. Changing `slave` does NOT affect `master`, and the change persists in
  //    `slave` until/unless it's resynced.
  // 4. `slave` gets a method, `syncWith`, which can be used as follows:
  //          slave.syncWith('resync');   // Set `slave` to `master`'s current value
  //          slave.syncWith('destroy');  // Remove the synchronization entirely
  //    `syncWith` returns `slave` for chaining.
  //
  // Example:
  //
  //      slave = ko.observable();
  //      master = ko.observable("foo");
  //      slave.extend({syncWith: master});
  //      console.log(master()); // "foo"
  //      console.log(slave());  // "foo"
  //      slave("bar");
  //      console.log(master()); // "foo"
  //      console.log(slave());  // "bar"
  //      master("update");
  //      console.log(master()); // "update"
  //      console.log(slave());  // "update"
  //      slave("fooboo");
  //      console.log(master()); // "update"
  //      console.log(slave());  // "fooboo"
  //      slave.syncWith('resync');
  //      console.log(master()); // "update"
  //      console.log(slave());  // "update"
  ko.extenders.syncWith = function(slave, master) {
    var syncData;

    if (!ko.isObservable(master)) {
      throw cr.createException("syncWith's option must be an observable");
    }

    syncData = {
      slave: slave,
      master: master
    };
    slave.syncWith = syncWithSimple.bind(syncData);
    syncWithSetter(slave, master());
    syncData.subscription = master.subscribe(function(newValue) {
      syncWithSetter(slave, newValue);
    });
  };

  function syncWithSetter(slave, value) {
    slave(Array.isArray(value) ? value.clone() : value);
  }

  function syncWithSimple(command) {
    var slave = this.slave;
    switch (command) {
      case "resync":
        syncWithSetter(slave, this.master());
        break;
      case "destroy":
        this.subscription.dispose();
        this.subscription = this.slave = this.master = undefined;
        delete slave.syncWith;
        break;
      default:
        throw cr.createException("Invalid syncWith command: '" + command + "'");
    }
    return slave;
  }

  // Creates a boolean observable representing that something is in progress
  // (just about any "ing" observable), with two extension functions:
  //     start - Starts the thing
  //     stop  - Stops the thing
  //     reset - Set the thing back to zero starts
  // The observable starts out false, and becomes true when you call start.
  // It becomes false again when you call stop.
  // You have to call stop the same number of times as start, or you can use
  // reset. Do not directly set the value of the observable.
  ko.startStopObservable = function() {
    var rv = ko.observable(false),
      counter = 0;

    rv.start = function() {
      if (++counter === 1) {
        rv(true);
      }
      if (rv.debug && typeof console !== "undefined") {
        // tslint:disable-next-line:no-console
        console.log(Date.now() + ": " + rv.debug + ": Start, counter now = " + counter);
      }
    };
    rv.stop = function() {
      if (counter > 0) {
        if (--counter === 0) {
          rv(false);
        }
        if (rv.debug && typeof console !== "undefined") {
          // tslint:disable-next-line:no-console
          console.log(Date.now() + ": " + rv.debug + ": Stop, counter now = " + counter);
        }
      } else if (rv.debug && typeof console !== "undefined") {
        // tslint:disable-next-line:no-console
        console.log(Date.now() + ": " + rv.debug + ": Stop, but wasn't running, no-op");
      }
    };
    return rv;
  };

  /* Sets an elements text based on an id/key lookup from a specified list */
  ko.bindingHandlers.lookup = {
    init: lookupBinding,
    update: lookupBinding
  };

  function lookupBinding(element, valueAccessor, allBindings, viewModel, bindingContext) {
    var value = valueAccessor();
    var valueUnwrapped = $.trim(ko.unwrap(value));
    var $el = $(element);

    var options = $.extend(
      {
        idProperty: "code",
        textProperty: "description",
        noneFound: "",
        tooltip: false
      },
      allBindings.get("lookupOptions")
    );

    var newValue = ko.utils.arrayFirst(options.list, function(item) {
      return ko.unwrap(item[options.idProperty]) == valueUnwrapped;
    });

    let text = newValue ? ko.unwrap(newValue[options.textProperty]) : options.noneFound;

    $el.text(text);

    if (options.tooltip) {
      $el.attr("title", text);
    }
  }

  // A simple handler to bind individual options rather than using the options binding
  // on the select; useful for when you have optgroups
  //    <select data-bind="foreach: groups, value: selectedOption">
  //        <optgroup data-bind="attr: {label: label}, foreach: children">
  //            <option data-bind="text: label, option: $data"></option>
  //        </optgroup>
  //    </select>
  // From RP: http://stackoverflow.com/a/11190148/157247
  ko.bindingHandlers.option = {
    update: function(element, valueAccessor) {
      var value = ko.utils.unwrapObservable(valueAccessor());
      ko.selectExtensions.writeValue(element, value);
    }
  };

  // `counted` binding: Shows a number followed by a counting word in the singular or plural
  // as appropriate.
  //
  // Example: data-bind="counted: {value: x, base: 'hour'}"
  // -> shows "1 hour" or "23 hours" depending.
  //
  // Specify `base`, `plural`, or both depending on your needs. The binding will figure out
  // the one you don't give based on the one you do, allowing for the "y" <-> "ies" thing.
  //
  // ONLY HANDLES A COUPLE OF PLURAL TYPES, see `makePlural` below.
  // If you have a weird plural, specify both `base` and `plural`.
  ko.bindingHandlers.counted = {
    update: function(element, valueAccessor) {
      var o = ko.toJS(valueAccessor());
      var num = o.value;
      if (!o.base && !o.plural) {
        throw new Error("'counted' binding requires either 'base' or 'plural'");
      }
      var word = getWord(num, o.base, o.plural);
      $(element).text(num + " " + word);
    }
  };

  // `countWord` binding: Same as `counted`, but only the word, not the value (in case they
  // need to appear in separate places.
  //
  // Example: data-bind="counted: {value: x, base: 'hour'}"
  // -> shows "hour" or "hours" depending on value.
  //
  // SEE CAVEATS on `counted`.
  ko.bindingHandlers.countWord = {
    update: function(element, valueAccessor) {
      var o = ko.toJS(valueAccessor());
      if (!o.base && !o.plural) {
        throw new Error("'countWord' binding requires either 'base' or 'plural'");
      }
      var word = getWord(o.value, o.base, o.plural);
      $(element).text(word);
    }
  };

  // Used by `counted` and `countWord`
  function getWord(num, base, plural) {
    var word = num == 1 ? base : plural;
    if (!word) {
      if (num == 1) {
        word = makeBase(plural);
      } else {
        word = makePlural(base);
      }
    }
    return word;
  }
  function makePlural(base) {
    var plural;
    if (base.endsWith("y")) {
      // thingy => thingies
      plural = base.substring(0, base.length - 1) + "ies";
    } else if (base.endsWith("s")) {
      // class => classes
      plural = base + "es";
    } else {
      // thing => things
      plural = base + "s";
    }
    return plural;
  }
  function makeBase(plural) {
    var base;
    if (plural.endsWith("ies")) {
      base = plural.substring(0, plural.length - 3) + "y";
    } else if (plural.endsWith("es")) {
      base = plural.substring(0, plural.length - 2);
    } else {
      base = plural.substring(0, plural.length - 1);
    }
    return base;
  }

  // `disableClear` binding: same as disable, but clears input when disabled and restores it when enabled
  // for now, this is one-way (does not listen on disabled/enabled change events)

  ko.bindingHandlers.disableClear = {
    init: function(element, valueAccessor, allBindingsAccessor) {
      allBindingsAccessor().value.subscribe(function() {
        $(element).data("lastValue", undefined);
      });
    },
    update: function(element, valueAccessor) {
      var disabled = !!ko.unwrap(valueAccessor());
      var state = !!$(element).prop("disabled");
      if (!(disabled ^ state)) return;
      var lastValue;
      $(element)
        .prop("disabled", disabled)
        .val(function(index, old) {
          if (disabled) lastValue = old;
          return disabled ? "" : $(this).data("lastValue");
        })
        .change()
        .data("lastValue", lastValue);
    }
  };

  // `selectedText` binding: binds an observable to the text in <select> (as opposed to value), one-way
  // http://stackoverflow.com/questions/11166504/knockout-bind-text-label-to-dropdown-value-selected-option-text

  ko.bindingHandlers.selectedText = {
    init: function(element, valueAccessor) {
      var value = valueAccessor();
      value($("option:selected", element).text());

      $(element).change(function() {
        value($("option:selected", this).text());
      });
    }
  };
  /**
   * Does not allow observable date to become empty, if the new value is empty or falls
   * below the supplied minimum date, the observable is set to the previous value.
   * @param {observable} target
   * @param {string} minDate
   */
  ko.extenders.observableDateRequired = function(target, minDate = "01/01/1000") {
    let _previousDate;

    target.subscribe(
      function(value) {
        _previousDate = moment(value);
      },
      undefined,
      "beforeChange"
    );

    target.subscribe(function(value) {
      const newDate = moment(value, "MM-DD-YYYY"),
        newDateInvalid = !newDate.isValid() || newDate.isBefore(minDate),
        previousDateValid = _previousDate.isValid() && _previousDate.isAfter(minDate);

      if (newDateInvalid && previousDateValid) {
        target(_previousDate.format("MM/DD/YYYY"));
      }
    });
    return target;
  };

  // This creates a custom ko binding similar to existing `data-bind="value: ..."` binding
  // instad of collecting the value as is, it converts it to a number first
  // this ensures that inside the observable there's a number if it's valid,
  // otherwise it returns the actual input to allow further validation.
  ko.bindingHandlers.numberValue = {
    init: function(element, valueAccessor, allBindings) {
      // This prevents to input 'e'
      // since a number input would normally allow it
      element.addEventListener('keydown', e => {
          if (e.key == 'e') {
              e.preventDefault();
          }
      });

      const defaultValueAccessor = valueAccessor;
      valueAccessor = function numberValueAccessor() {
        const observable = defaultValueAccessor();
        const numberObservable = function numberObservable() {
          if (arguments.length === 0) {
            return observable();
          }

          let number = arguments[0];
          const valueAsNumber = Number(number);

          // `valueAsNumber.toString().indexOf('e') < 0` prevents js to conver large number to `e notation` which results in a wrong error message (because e is not a valid number for the validator)
          // `!Number.isNaN(number)` prevents js to conver to 0, so that the user receives an error message (0 is valid otherwise)
          if (typeof number !== 'number' && valueAsNumber.toString().indexOf('e') < 0 && !Number.isNaN(number)) {
            number = valueAsNumber;
          }

          observable(number);
          return this;
        };

        numberObservable.__proto__ = observable;
        return numberObservable;
      };
      ko.bindingHandlers.value.init(element, valueAccessor, allBindings);
    }
  };

  ko.extenders.numericPrecision = function(target, precision) {
    //create a writable computed observable to intercept writes to our observable
    var result = ko.pureComputed({
        read: target,  //always return the original observables value
        write: function(newValue) {
          var current = target();
          var valueToWrite = newValue;
          var newValueParsed = parseFloat(newValue);
          var decimals = /[^\d](\d+)$/;
          var matches = newValue && newValue.match(decimals);
          if (matches) {
            if (matches[1].length > precision) {
              valueToWrite = newValueParsed.toFixed(precision);
            }
          }
          //only write if it changed
          if (valueToWrite !== current) {
            target(valueToWrite);
        } else {
            //if the rounded value is the same, but a different value was written, force a notification for the current field
            if (newValue !== current) {
                target.notifySubscribers(valueToWrite);
            }
        }
        }
    }).extend({ notify: 'always' });

    //initialize with current value to make sure it is rounded appropriately
    result(target());

    //return the new computed observable
    return result;
  };

})();
