define("widgets/cr-labelWidget", [
  "framework/globalUtils/koNodePreprocessor",
  "widgets/labelWidgetHtml/labelWidgetDropdownContent.htm",
  "widgets/labelWidgetHtml/labelWidgetReadOnlyDropdownContent.htm",
  "widgets/labelWidgetHtml/labelWidgetInlineContent.htm"
], function(widgets, dropdownContent, readOnlyDropdownContent, inlineContent) {
  /*
    * This widget defines a ko binding and a ko preprocessor.  The binding is applied to the master table / grid.  It sets up some delegated jquery events
    * to enable some bootstrap dropdowns that expose label functionality for each item.  Rather than render a dropdown for each and every item, ONE is created
    * for each label type, and is swapped around just in time as the user clicks on the label button.
    *
    * This code was written with an eye toward memory usage—perhaps unnecessarily so: as described above, a single label dropdown is shared by all, and
    * jquery's .data is eschewed in favor of the attr function.
    *
    * The preprocessor renders the label button itself.  One will be added for each label type.  The custom tag is expected to have all needed configuration data in it.
    */

  widgets.addElementHandler({
    type: "label-widget-drop",
    process: function($node) {
      var config = eval("({" + $node.attr("config") + "})"),
        btnContent = $(
          inlineContent
            .replace(/#{collection}/g, config.displayCollection || config.collection)
            .replace(/#{buttonClass}/g, config.buttonClass || "btn-xs")
            .replace(/#{name}/g, config.name)
        );

      btnContent
        .find(".label-widget-toggle")
        .attr(config.titleOverride || "title", "Click to view " + config.header)
        .addClass(config.classList || "");

      return $('<span class="widget-root"></span>')
        .attr("data-collection", config.collection)
        .attr("data-item-id", config.itemId)
        .attr("data-channel-override", config.channelOverride)
        .attr("data-saveaction", config.saveAction)
        .attr("data-name", config.name)
        .attr("data-header", config.header)
        .attr("data-dropright", config.dropRight)
        .attr("data-add-remove-permission", config.addRemovePermission)
        .attr("data-org-label-permission", config.orgLabelsPermission)
        .append(btnContent);
    }
  });

  widgets.addElementHandler({
    type: "readonly-label-widget-drop",
    process: function($node) {
      var config = eval("({" + $node.attr("config") + "})"),
        btnContent = $(
          inlineContent
            .replace(/#{collection}/g, config.collection)
            .replace(/#{buttonClass}/g, config.buttonClass || "btn-xs")
            .replace(/#{name}/g, config.name)
        );

      btnContent
        .removeClass("label-widget-item")
        .addClass("label-widget-readonly-item")
        .find(".label-widget-toggle")
        .attr(config.titleOverride || "title", "Click to view " + config.header)
        .addClass(config.classList || "");

      return $('<span class="widget-root"></span>')
        .attr("data-collection", config.collection)
        .attr("data-name", config.name)
        .attr("data-header", config.header)
        .append(btnContent);
    }
  });

  function getContentTemplate(name, listViewModel, $rootEl) {
    var $node = getContent(name, $rootEl);
    ko.applyBindings(listViewModel, $node[0]);
    return $node;
  }

  function getContent(name, $rootEl) {
    return $(
      dropdownContent
        .replace(/#{name}/g, name)
        .replace(/#{header}/g, $rootEl.attr("data-header"))
        .replace(/#{addRemovePermission}/g, $rootEl.attr("data-add-remove-permission"))
        .replace(/#{dropRight}/g, $rootEl.attr("data-dropright") ? "pull-right" : "")
    );
  }

  function getReadonlyContentTemplate(name, listViewModel, $rootEl) {
    var $node = getReadonlyContent(name, $rootEl);
    ko.applyBindings(listViewModel, $node[0]);
    return $node;
  }

  function getReadonlyContent(name, $rootEl) {
    return $(readOnlyDropdownContent.replace(/#{name}/g, name).replace(/#{header}/g, $rootEl.attr("data-header")));
  }

  ko.bindingHandlers.crLabelWidget = {
    init: function(element, valueAccessor) {
      var options = ko.utils.unwrapObservable(valueAccessor());

      var channelId = options.channelId;

      $(element).on("click", ".label-widget-item > ul", function() {
        return false;
      });
      $(element).on("click", ".label-widget-readonly-item .label-widget-toggle", function() {
        var name = $(this)
            .closest(".label-widget-item")
            .attr("data-name"),
          nestedLabelsSrc = options.listVm || options.nestedLabelsSrc; //adding an alias for alternate use with the smarter labels component

        if (options.labelSourceOverride) {
          nestedLabelsSrc = options.labelSourceOverride[name] || nestedLabelsSrc;
        }
        if (typeof nestedLabelsSrc["currentLabelWidgetSource" + name] === "undefined") {
          nestedLabelsSrc["currentLabelWidgetSource" + name] = ko.observable(null);
        }

        var content = $("#label-widget-content-" + name),
          item = ko.dataFor(this),
          widgetRoot = $(this).closest(".widget-root"),
          itemsLabels = item[widgetRoot.data("collection")];

        if (!content.length) {
          var $rootEl = $(this).closest(".widget-root");
          content = getReadonlyContentTemplate(name, nestedLabelsSrc, $rootEl);
        }

        nestedLabelsSrc["currentLabelWidgetSource" + name]({ labelCollection: itemsLabels });
        $(this).after(content);
      });
      $(element).on("click", ".label-widget-item .label-widget-toggle", function() {
        var name = $(this)
            .closest(".label-widget-item")
            .attr("data-name"),
          nestedLabelsSrc = options.listVm || options.nestedLabelsSrc; //adding an alias for alternate use with the smarter labels component

        if (options.labelSourceOverride) {
          nestedLabelsSrc = options.labelSourceOverride[name] || nestedLabelsSrc;
        }
        if (typeof nestedLabelsSrc["currentLabelWidgetSource" + name] === "undefined") {
          nestedLabelsSrc["currentLabelWidgetSource" + name] = ko.observable(null);
        }

        var content = $("#label-widget-content-" + name),
          item = ko.dataFor(this),
          widgetRoot = $(this).closest(".widget-root"),
          itemsLabels = item[widgetRoot.data("displayCollection") || widgetRoot.data("collection")],
          itemsLabelsMutable = item[widgetRoot.data("collection")],
          saveAction = widgetRoot.data("saveaction"),
          orgLabelPermission = +widgetRoot.data("orgLabelPermission"),
          hasOrgLabelsPermission = !!orgLabelPermission && cr.getUser().hasPermission(orgLabelPermission),
          itemsSelectedIds = ko.computed(function() {
            var allLabelIds = nestedLabelsSrc.allLabels().map(function(l) {
              return +l.id();
            });
            return ko
              .unwrap(itemsLabels)
              .filter(function(l) {
                return allLabelIds.indexOf(+l.id()) > -1;
              })
              .map(function(l) {
                return +l.id();
              });
          }, item);

        if (!content.length) {
          var $rootEl = $(this).closest(".widget-root");
          content = getContentTemplate(name, nestedLabelsSrc, $rootEl);
        }

        nestedLabelsSrc["currentLabelWidgetSource" + name]({
          labelCollection: itemsLabels,
          itemsCurrentlySelectedIds: itemsSelectedIds,
          $parent: nestedLabelsSrc,
          labelAddedOrRemoved: labelAddedOrRemoved,
          hasOrgLabelsPermission: hasOrgLabelsPermission,
          isOrg: cr.getUser().isCurrentlyTheOrganization()
        });

        $(this).after(content);

        function labelAddedOrRemoved(obj, k) {
          if (!(obj.added || obj.removed)) {
            return;
          }

          var channelOverride = widgetRoot.attr("data-channel-override"),
            idOverride = widgetRoot.attr("data-item-id"),
            idProperty = idOverride || "id";

          if (obj.added)
            itemsLabels.push(
              cr.viewModelFactory.commonViewModels.individualLabelVm.createFromResponse($.extend(obj.added, { name: obj.added.text }))
            );
          if (obj.removed)
            itemsLabels.remove(function(l) {
              return l.id() == obj.removed.id;
            });

          var itemId = typeof item[idProperty] === "function" ? item[idProperty]() : item[idProperty];

          if (itemId && (obj.added || obj.removed)) {
            var req = { ids: itemId, add: obj.added ? obj.added.id : "", remove: obj.removed ? obj.removed.id : "" };
            cr.transport.transmitRequest(channelOverride || channelId, saveAction, req, function() {
              cr.router.currentModuleBusConnection.sendMessage("cr-label-widget.labels-added-or-removed", req);
            });
          }
        }
      });
    }
  };
});
