import { IStore, StoreStatus } from "./store";

/**
 * Automatically sets the Store's status to `Loading` while executing the method
 * and `Loaded` once the method successfully completes (or `Error` if it fails).
 */
export function loadingAction(target: IStore, action: (...args) => any);
/**
 * Sets the Store's status to `Loading` while executing the method
 * and `Loaded` once the method successfully completes (or `Error` if it fails).
 */
export function loadingAction(target: IStore, property: string, descriptor?: PropertyDescriptor);
export function loadingAction(target: IStore, action: string | ((...args) => any), descriptor?: PropertyDescriptor) {
  if (typeof action === "string") {
    applyLoadingActionDecorator(target, action, descriptor);
  } else {
    executeLoadingAction(target, action);
  }
}

function applyLoadingActionDecorator(target: IStore, propertyKey: string, descriptor: PropertyDescriptor) {
  const originalMethod = descriptor.value;

  descriptor.value = function loadingAction() {
    return executeLoadingAction(this, originalMethod.bind(this, ...arguments));
  };
}

function executeLoadingAction(store: IStore, action: () => any) {
  let setStatus = (store as any).setStatus;

  // this only applies to Stores who have the `Store.setStatus()` method
  // (which should be all of them, but may have been overridden, removed, whatever)
  if (typeof setStatus !== "function") {
    throw Error("@loadingAction expects this.setStatus([StoreStatus]) method to exist");
  } else {
    setStatus = setStatus.bind(store);
  }

  setStatus(StoreStatus.Loading);

  let result;

  try {
    result = action();

    const isPromise = result != null && typeof result.then === "function";

    if (isPromise) {
      result = result.then(() => setStatus(StoreStatus.Loaded)).catch(() => setStatus(StoreStatus.Error));
    } else {
      setStatus(StoreStatus.Loaded);
    }
  } catch (e) {
    setStatus(StoreStatus.Error);
  }

  return result;
}
