ko.bindingHandlers.syncCheckboxList = {
  init: function(element, valueAccessor, allBindings) {
    var rawArr = valueAccessor(),
      $element = $(element),
      manuallyAdjusting = false,
      ab = allBindings(),
      foreach = ab.foreach || {};

    var updateWithPacket = rawArr[cr.checkBoxSyncPacket] || getDefaultSyncPacket();
    var toDispose = [];

    $element.on("click", "input:checkbox", checkBoxClick);
    syncCheckboxesToCurrentArrayValues();
    rawArr.subscribe && toDispose.push(rawArr.subscribe(syncCheckboxesToCurrentArrayValues));
    foreach.subscribe && toDispose.push(foreach.subscribe(syncCheckboxesToCurrentArrayValues));

    function syncCheckboxesToCurrentArrayValues() {
      if (manuallyAdjusting) return;

      var listOfValues = ko.unwrap(rawArr);
      $element.find("input:checkbox").prop("checked", false);
      listOfValues.forEach(function(arrayValue) {
        var domValue = updateWithPacket.extractWith(arrayValue);
        $element.find('input:checkbox[value="' + domValue + '"]').prop("checked", true);
      });
    }

    ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
      $element.off("click", checkBoxClick);
      $element = null;
      rawArr = null;
      toDispose.forEach(d => d.dispose && d.dispose());
    });

    function checkBoxClick(evt) {
      manuallyAdjusting = true;

      var checked = this.checked,
        val = this.value,
        listOfValues = ko.unwrap(rawArr),
        itemInArray = listOfValues.find(updateWithPacket.findWith(val)),
        index = listOfValues.indexOf(itemInArray);

      if (checked) {
        if (index == -1) {
          listOfValues.push(updateWithPacket.projectWith(val, ko.dataFor(this)));
        }
      } else {
        if (index > -1) {
          listOfValues.splice(index, 1);
        }
      }

      setTimeout(() => rawArr.valueHasMutated && rawArr.valueHasMutated(), 1); //async - let subscriptions fire after we're out of manuallyAdjusting mode
      manuallyAdjusting = false;
    }

    function getDefaultSyncPacket() {
      return {
        findWith: target => id => target == id,
        extractWith: val => val,
        projectWith: val => val
      };
    }
  }
};

ko.bindingHandlers.deferredDropdownRender = {
  init: function(element, valueAccessor, allBindings, viewModel, bindingContext) {
    let options = ko.unwrap(valueAccessor()),
      $element = $(element),
      $deferredContent = $element.find(options.contentSelector);

    let counter = 1;
    let nodeLookup = {};

    $deferredContent.each(function() {
      let $this = $(this),
        $dropdownToggle = $this.parent().find('[data-toggle="dropdown"]');

      $dropdownToggle.attr("data-dropdowncontent", `val${counter}`);
      nodeLookup[`val${counter}`] = $this.detach();
      counter++;
    });

    $element.on("show.bs.dropdown", function(evt) {
      let node = evt.target,
        context = ko.contextFor(node),
        $dropdown = $(evt.target).find('[data-toggle="dropdown"]');

      if (!nodeLookup[$dropdown.attr("data-dropdowncontent")]) return; //this may be a "regular" dropdown

      let contentNode = nodeLookup[$dropdown.attr("data-dropdowncontent")].clone()[0];

      ko.applyBindings(context, contentNode);
      $dropdown.after(contentNode);
    });

    $element.on("hide.bs.dropdown", function(evt) {
      var $dropdown = $(evt.target).find(".dropdown-menu");
      if (!$dropdown.is(options.contentSelector)) return; //this may be a "regular" dropdown - don't remove it!

      ko.removeNode($dropdown[0]);
    });

    ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
      nodeLookup = null;
      $element.off(); //think this is un-needed, but....
    });
  }
};

ko.bindingHandlers.delegatedCollapse = {
  init: function(element, valueAccessor, allBindings) {
    let cssUp = "fa-caret-right",
      cssDown = "fa-caret-down";

    $(element).on("hide.bs.collapse", function(evt) {
      let targetId = evt.target.id,
        $source = $(`[data-target="#${targetId}"]`, element);

      if ($source.length === 1 && $source.find(`i.${cssDown}`)) {
        $source.find(`i.${cssDown}`).toggleClass(`${cssUp} ${cssDown}`);
      }
    });

    $(element).on("show.bs.collapse", function(evt) {
      let targetId = evt.target.id,
        $source = $(`[data-target="#${targetId}"]`, element);

      if ($source.length === 1 && $source.find(`i.${cssUp}`)) {
        $source.find(`i.${cssUp}`).toggleClass(`${cssUp} ${cssDown}`);
      }
    });
  }
};

//syncs your current bootstrap tab to a ko observable, and if you manually change the observable, the tab will change for you
ko.bindingHandlers.bootstrapTabSync = {
  init: function(element, valueAccessor) {
    var vmProp = valueAccessor();

    var $activeA = $(element)
      .find("li.active")
      .find("a");
    if ($activeA.length) {
      vmProp($activeA.attr("href").replace("#", ""));

      //remove active class before clicking because otherwise bootstrap will short circuit out of the show method,
      // which may leave the wrong tab content visible, since the tab content divs may be separate from the tab headers, and
      // outside of this binding, meaning the code that resets THESE nodes may not in fact also reset the content nodes.
      $activeA.closest("li.active").removeClass("active");
      $activeA.tab("show");
    }

    $(element).on("click", '[data-toggle="tab"]', function() {
      vmProp(
        $(this)
          .attr("href")
          .replace("#", "")
      );
    });
    var sub = vmProp.subscribe(function(val) {
      $(element)
        .find('a[href="#' + val + '"]')
        .tab("show");
    });
    ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
      sub.dispose();
    });
  }
};

// -----------------------------------------------
// Bootstrap Tooltip
// -----------------------------------------------
// Bind Twitter Tooltip delegated tooltips
ko.bindingHandlers.delegatedTooltips = {
  init: function(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
    var $element = $(element),
      options = ko.utils.unwrapObservable(valueAccessor()),
      selector = "[data-title-bind],[data-title]",
      { closeOnClick, closeOnMouseDown, strictMode } = options;
    function getTooltipContent() {
      var $el = $(this),
        text = $el.attr("data-title"),
        textFunction = $el.attr("data-title-bind");

      if (!$el.is(":visible")) return null;
      if (strictMode && !$el.is(":hover")) return null;
      return textFunction ? ko.unwrap(ko.dataFor(this)[textFunction]) : text || "";
    }
    $element.tooltip($.extend(options, { selector: selector, container: "body", title: getTooltipContent }));
    if (closeOnClick)
      $element.on("click", selector, function() {
        $(this).tooltip("hide");
      });

    if (closeOnMouseDown) {
      $element.on("mousedown", selector, function() {
        $(this).tooltip("hide");
      });
    }
  }
};

// Bind Twitter Tooltip
ko.bindingHandlers.tooltip = {
  init: function(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
    var options = $.extend({ container: "body" }, ko.utils.unwrapObservable(valueAccessor())),
      $element = $(element),
      { closeOnClick } = options;

    if (closeOnClick)
      $element.on("click", function() {
        $(this).tooltip("hide");
      });
  },
  update: function(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
    var $element, options, tooltip;
    options = $.extend({ container: "body" }, ko.utils.unwrapObservable(valueAccessor()));
    $element = $(element);
    tooltip = $element.data("tooltip");

    if (options.stayOnHover) options.container = $element;

    if (tooltip) {
      $.extend(tooltip.options, options);
    } else {
      $element.tooltip(options);
    }
  }
};

ko.bindingHandlers.syncBootstrapListTo = {
  init: function(element, valueAccessor) {
    var sub = valueAccessor().subscribe(setIt);

    function setIt(val) {
      $("li.active", element).removeClass("active");
      $('li[data-value="' + val + '"]', element).addClass("active");
    }
    ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
      element = null;
      sub.dispose();
      sub = null;
    });
    setIt(valueAccessor()());
  }
};

ko.bindingHandlers.bootstrapModalBind = {
  init: function(element, valueAccessor, all, vm, context) {
    var $el = $(element);
    $el.addClass("modal");

    var bootstrapOptions = { show: false };
    if ($el.attr("bootstrap-options")) {
      $.extend(bootstrapOptions, eval("(" + $el.attr("bootstrap-options") + ")"));
    }

    if (valueAccessor().openWhen) {
      $el.modal(bootstrapOptions).on("hidden.bs.modal", function(e) {
        e.stopPropagation();
        valueAccessor().openWhen(false);
      });

      var openWhenSubscription = valueAccessor().openWhen.subscribe(function(val) {
        valueAccessor();
        element;
        vm;
        $el.modal(val ? "show" : "hide");
      });

      setupEvents(valueAccessor().events);

      ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
        openWhenSubscription.dispose();
      });

      return;
    }

    var templateOptionsComputed = ko.computed(function() {
      var templateOptions = {},
        options = valueAccessor(),
        data = getData();

      //allow passing in a template name
      if (typeof options === "object" && options.template) {
        templateOptions.name = options.template;
      }

      templateOptions["if"] = data;
      templateOptions.data = data;
      return templateOptions;
    });

    //apply the template binding to the element with the appropriate data/template
    ko.applyBindingsToNode(element, { template: templateOptionsComputed }, context);

    //initialize the modal and add a "hidden" callback to clear the data
    $el.modal(bootstrapOptions).on("hidden.bs.modal", function(e) {
      e.stopPropagation();
      var data = getData();
      data(null);
    });

    var events = valueAccessor().events;
    setupEvents(events);

    //show/hide the modal based on whether the data is populated
    ko.computed({
      read: function() {
        var data = getData();
        $el.modal(ko.unwrap(data) ? "show" : "hide");
      },
      disposeWhenNodeIsRemoved: element
    });

    return { controlsDescendantBindings: true };

    //helper to get data - ensures valueAccessor is called
    function getData() {
      var valuePassed = valueAccessor();
      return valuePassed.data || valuePassed;
    }

    function setupEvents(events) {
      if (events) {
        Object.keys(events).forEach(function(name) {
          $el.on(name + ".bs.modal", function(e) {
            e.stopPropagation();
            let possibleEventFunc = events[name];
            // if event was bound as a function
            if (typeof possibleEventFunc === "function") {
              return possibleEventFunc(e);
            }
            if (typeof possibleEventFunc === "string") {
              // event may have been passed as a string name, so see if it exists on the vm
              var vm = ko.unwrap(getData());
              if (vm) {
                possibleEventFunc = vm[possibleEventFunc];
                if (!typeof possibleEventFunc === "function") {
                  console.error(
                    `Expected Bootstrap Modal event handler to either be a function or the name of a function on the contextual VM. Name: ${
                      events[name]
                    }`
                  );
                  return;
                }
                return possibleEventFunc(e);
              }
            }
          });
        });
      }
    }
  }
};

// -----------------------------------------------
// Bootstrap Popover
// -----------------------------------------------
// Bind Twitter Popover delegated popovers
ko.bindingHandlers.delegatedPopovers = {
  init: function(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
    var $element = $(element),
      options = ko.utils.unwrapObservable(valueAccessor()),
      selector = "[data-content-bind],[data-content]",
      { closeOnClick } = options;

    function getPopoverContent() {
      $(".popover").popover("hide");

      var $el = $(this),
        text = $el.attr("data-content"),
        textFunction = $el.attr("data-content-bind"),
        funcUnwrapped = ko.unwrap(ko.dataFor(this)[textFunction]);

      return textFunction ? (typeof funcUnwrapped === "function" ? funcUnwrapped() : textFunction) : text || "";
    }

    $element.popover($.extend({ trigger: "hover" }, options, { selector: selector, container: "body", content: getPopoverContent }));
    if (closeOnClick)
      $element.on("click", selector, function() {
        $(this).popover("hide");
      });
  }
};

// Bind Twitter Tooltip
ko.bindingHandlers.popover = {
  update: function(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
    var $element, options, popover;
    options = $.extend({ container: "body" }, ko.utils.unwrapObservable(valueAccessor()));
    $element = $(element);
    let { closeOnClick } = options;
    if (options.stayOnHover) options.container = $element;

    if (closeOnClick)
      $element.on("click", function() {
        $(this).popover("hide");
      });

    // asynch popover
    // options.content: function(data) viewmodel function which fetches the popover content, must return a promise
    // and resolve with the newly loaded content
    if (options && options.async) {
      $element.popover({ html: true, trigger: "manual", content: "", container: options.container });
      popover = $element.data("bs.popover");

      if (options.trigger == "hover") {
        $element.hover(
          function(e) {
            if (typeof options.content === "function") {
              var promise = options.content($element.data());
              promise.done(function(content) {
                if (popover) {
                  popover.options.content = content;
                  $element.popover("show");
                }
              });
            }
          },
          function(e) {
            $element.popover("hide");
          }
        );
      } else {
        $element.click(function(e) {
          if (typeof options.content === "function") {
            var promise = options.content($element.data());
            promise.done(function(content) {
              if (popover) {
                popover.options.content = content;
                $element.popover("toggle");
              }
            });
          }
        });
      }
      // basic popover
    } else {
      // does this also need to be updated to use bs.popover instead of popover?
      popover = $element.data("popover");
      if (popover) {
        $.extend(popover.options, options);
      } else {
        $element.popover(options);
      }
    }
  }
};

ko.bindingHandlers.accordion = {
  init: function(element, valueAccessor, allBindingsAccessor) {
    var selector = ko.unwrap(valueAccessor);

    $(element).on("click", selector(), function() {
      var $item = $(this);
      $item.next().toggle("slow", function() {
        var vm = ko.dataFor(this);
        if (vm.isVisible) {
          setTimeout(function() {
            var isVisible = $item.next().is(":visible");

            if (isVisible) {
              $item.next().addClass("in");
            } else {
              $item.next().removeClass("in");
            }

            vm.isVisible(isVisible);
          }, 0);
        }
      });
      return false;
    });
  },
  update: function(element, valueAccessor) {}
};

ko.bindingHandlers.accordionWithTarget = {
  init: function(element, valueAccessor, allBindingsAccessor) {
    var selectors = ko.unwrap(valueAccessor()),
      $el = $(element),
      $target = $el.find(selectors.target);

    $el.on("click", selectors.source, function() {
      $target.toggle("slow", function() {
        var vm = ko.dataFor(this);
        if (vm.isVisible) {
          setTimeout(function() {
            vm.isVisible($target.is(":visible"));

            if (vm.isVisible()) {
              $target.addClass("in");
            } else {
              $target.removeClass("in");
            }
          }, 0);
        }
      });
      return false;
    });
  },
  update: function(element, valueAccessor) {}
};
