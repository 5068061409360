ko.bindingHandlers.circleChart = {
  init: circleChart,
  update: circleChart
};

function circleChart(element, valueAccessor, allBindings, viewModel, bindingContext) {
  var $el = $(element),
    $ppc = $el.find(".progress-pie-chart"),
    percent = ko.unwrap(valueAccessor()),
    deg = 360 * percent / 100;
  if (percent > 50) {
    $ppc.addClass("gt-50");
  } else {
    $ppc.removeClass("gt-50");
  }
  $el.find(".ppc-progress-fill").css("transform", "rotate(" + deg + "deg)");
  $el.find(".ppc-percents:not(.custom-text) span").html(percent.toFixed(0) + "%");
}

ko.bindingHandlers.slideVisible = {
  init: function(element, valueAccessor, allBindingAccessor, vm) {
    // Initially set the element to be instantly visible/hidden depending on the value
    var value = valueAccessor(),
      ab = allBindingAccessor(),
      $element = $(element);

    $(element).toggle(!!ko.utils.unwrapObservable(value)); // Use "unwrapObservable" so we can handle values that may or may not be observable

    if (ab.slidePromiseAs) {
      vm[ab.slidePromiseAs] = function(callback) {
        $.when($element).then(function() {
          callback.call(vm);
        });
      };
    }
  },
  update: function(element, valueAccessor, allBindingAccessor) {
    var value = valueAccessor(),
      ab = allBindingAccessor();

    if (ko.utils.unwrapObservable(value)) {
      $(element).slideDown();
    } else {
      if (!ab.suppressSlideUp) {
        $(element).slideUp();
      } else {
        $(element).hide();
      }
    }
  }
};

ko.bindingHandlers.fadeVisible = {
  init: function(element, valueAccessor) {
    // Initially set the element to be instantly visible/hidden depending on the value
    var value = valueAccessor();
    $(element).toggle(ko.utils.unwrapObservable(value)); // Use "unwrapObservable" so we can handle values that may or may not be observable
  },
  update: function(element, valueAccessor, allBindingsAccessor) {
    var value = ko.unwrap(valueAccessor()),
      $el = $(element),
      all = allBindingsAccessor();

    // If it's curently fading, stop and jump to the end; then do the fade
    // (it'll be a no-op if the element is already visible/not visible and
    // that's what we're doing)
    $el.stop(true, true);
    if (value) {
      if (all.hasOwnProperty("fadeInSpeed")) {
        $el.fadeIn(all.fadeInSpeed);
      } else {
        $el.fadeIn();
      }
    } else {
      if (all.hasOwnProperty("fadeOutSpeed")) {
        $el.fadeOut(all.fadeOutSpeed);
      } else {
        $el.fadeOut();
      }
    }
  }
};

ko.bindingHandlers.slideVisibleIfNew = {
  init: function(element, valueAccessor) {},
  update: function(element, valueAccessor, bindingaccessor, vm) {
    var value = valueAccessor();

    var afterSlideSet = (bindingaccessor() || {}).afterSlideSet;

    if (ko.utils.unwrapObservable(value)) {
      if (!vm.id()) {
        $(element).slideDown(500, function() {
          typeof afterSlideSet === "string" && vm[afterSlideSet](true);
        });
      } else {
        $(element).show();
      }
    } else {
      $(element)[!vm.id() ? "slideUp" : "hide"]();
    }
  }
};

//TODO: refactor this with slideVisibleIfNew
ko.bindingHandlers.fadeInIfNew = {
  init: function(element, valueAccessor) {},
  update: function(element, valueAccessor, bindingaccessor, vm) {
    var value = valueAccessor();

    var afterSlideSet = (bindingaccessor() || {}).afterSlideSet;

    if (ko.utils.unwrapObservable(value)) {
      if (!vm.id()) {
        $(element).fadeIn(500, function() {
          typeof afterSlideSet === "string" && vm[afterSlideSet](true);
        });
      } else {
        $(element).show();
      }
    } else {
      $(element)[!vm.id() ? "slideUp" : "hide"]();
    }
  }
};
