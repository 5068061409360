// tslint:disable:function-name
import hashUtility, { SerializedHash } from "app/util/hashUtility";
import { Action, createHashHistory, History, Location } from "history";
import { computed, observable, runInAction } from "mobx";
import isFrameWindow from "./isFrameWindow";
import { Logger } from "./logger";

export { History, Location };

type GetUserConfirmationFn = (message: string, callback: (result: boolean) => void) => void;

type NavigationHistoryAction = "back" | "forward" | "navigate" | "replace" | "unknown";
type HistoryAction = Action | "INIT";

interface HistoryAwareState {
  /**
   * Represents the position of the current page inside our history array stored in sessionStorage.
   */
  $index?: number;
}

/**
 * Navigation history stored in sessionStorage.
 */
interface StoredNavigationHistory {
  /**
   * Snapshot of the navigation history. Stores zeros in case of undefined history entries.
   */
  navigationHistory: (0 | Partial<Location<never>>)[];
  /**
   * Start index (> 0 in case of memory pressure)
   */
  index: number;
}

/**
 * A snapshot of the navigation history
 */
type NavigationHistoryState = {
  -readonly [P in keyof Pick<ObservableHistory, "navigationAction" | "navigationHistory" | "navigationHistoryIndex">]: ObservableHistory[P];
};

/**
 * Confirms immediately the navigation without asking the user.
 * This is the default implementation.
 */
const noUserConfirmation: GetUserConfirmationFn = (_message, callback) => callback(true);

/**
 * Checks if two instances created by hash history are the same
 */
const locationsAreEqual = (a: Location<never>, b: Location<never>) => a.pathname == b.pathname && a.search == b.search && a.hash == b.hash;

/**
 * Converts to string an history location
 */
const dumpLocation = (l: undefined | Location<never>) => l && `${l.pathname}${l.search}${l.hash ? "#" + l.hash : ""}`;

/**
 * Reference window history in a `global History` variable to differentiate it from "history" library
 */
const globalHistory = window.history;

/**
 * Our logger (for debugging purposes)
 */
const log = Logger.create("history");

/**
 * Session storage key where we save the location history
 */
const NAVIGATION_HISTORY_STORAGE_KEY = "history:nav";

/**
 * Do not enable navigation history management inside an iframe of the same origin
 * See https://stackoverflow.com/questions/326069/how-to-identify-if-a-webpage-is-being-loaded-inside-an-iframe-or-directly-into-t
 */
const isNavigationHistoryEnabled = () => {
  const enabled = !isFrameWindow();
  log.debug("NavHistoryEnabled", enabled);
  return enabled;
};

/**
 * Used by `currentHash.js` in a lot of legacy modules
 * @deprecated
 */
class SerializedHashStore {
  @observable module = "";
  @observable submodule = "";
  @observable subsubmodule = "";
  @observable parameters = Object.create(null);
  @observable __rawHash = "";
}

/**
 * A thin wrapper around the history API that makes it (or at least `location`) observable
 */
class ObservableHistory implements History {
  private _navigationHistoryEnabled: boolean;
  private _history: History<never> = createHashHistory({
    hashType: "noslash",
    getUserConfirmation: (message, callback) => {
      const askConfirmation = this.getUserConfirmation || noUserConfirmation;
      askConfirmation(message, callback);
    }
  });

  /**
   * Keeps the real navigation history of our application (independent of external websites navigation - for instance SSO re-logins)
   */
  @observable readonly navigationHistory: ReadonlyArray<undefined | Readonly<Location<never>>>;

  /**
   * Keeps the current navigation history index
   */
  @observable readonly navigationHistoryIndex: number;

  /**
   * Keeps the latest navigation action
   */
  @observable readonly navigationAction: NavigationHistoryAction;

  /**
   * Current history location
   */
  @observable readonly location: Location<never>;

  /**
   * Current queryParameters
   */
  @observable readonly queryParameters: { [key: string]: string } = {};

  /** @deprecated  DO NOT USE! */
  @computed
  get module() {
    return module;
  }

  get length() {
    return this._history.length;
  }

  /**
   * Gets the previous location in application's location history (go back location)
   */
  @computed
  get previousLocation() {
    const navigationHistory = this.navigationHistory;
    const navigationHistoryIndex = this.navigationHistoryIndex - 1;
    if (navigationHistoryIndex >= 0 && navigationHistoryIndex < navigationHistory.length) {
      return navigationHistory[navigationHistoryIndex];
    }
  }

  /**
   * Checks if the current location contains our special query parameter named `public` to identify unauthenticated routes.
   */
  @computed
  get isPublicPage() {
    return !!this.queryParameters.public;
  }

  /**
   * Checks if the current location contains our special query parameter named `popup` to identify child windows opened as popup.
   * A popup is redender without page header (no top menu/bars).
   */
  @computed
  get isPopUp() {
    return !!this.queryParameters.popup;
  }

  /**
   * Gets the next location in application's location history (go forward location)
   */
  @computed
  get nextLocation() {
    const navigationHistory = this.navigationHistory;
    const navigationHistoryIndex = this.navigationHistoryIndex + 1;
    if (navigationHistoryIndex >= 0 && navigationHistoryIndex < navigationHistory.length) {
      return navigationHistory[navigationHistoryIndex];
    }
  }

  /**
   * An observable snapshot of the current hash info used in the "new-but-old" knockout `src/app/router` via `HashWatcher`.
   */
  @observable readonly currentHash: SerializedHash;

  /**
   * Observable Mobx Store of the current hash info.
   * This is used by a lot of legacy modules via `src/app/util/currentHash`.
   *
   * @deprecated If you really need this, please try to use `currentHash` instead.
   */
  readonly legacyCurrentHash: SerializedHashStore;

  /**
   * A callback that'll be called when changing location and using `<Prompt when={true} message="Really want to leave the page?">`.
   * The function should ask the user if he wants to leave the page and then call the `callback` function with the result.
   *
   * This property is managed globally by `<App>` component in combination with `<RouteDeactivateGuard>`.
   * Please do not override it.
   *
   * @internal
   */
  getUserConfirmation?: GetUserConfirmationFn;

  constructor() {
    this._navigationHistoryEnabled = isNavigationHistoryEnabled();

    this.navigationHistory = [];
    this.navigationHistoryIndex = -1;
    this.navigationAction = "navigate";
    this.legacyCurrentHash = new SerializedHashStore();

    if (this._navigationHistoryEnabled) {
      // restore session history
      this._restoreNavigationHistoryState();
    }

    // set current location
    this._sync(this._history.location, "INIT");

    // listen on location changes
    this._history.listen(this._onLocationChange);
  }

  /** @deprecated please use navigationAction instead */
  action = this._history.action;
  listen = this._history.listen;
  push = this._history.push;
  replace = this._history.replace;
  block = this._history.block;
  go = this._history.go;
  goBack = this._history.goBack;
  goForward = this._history.goForward;
  createHref = this._history.createHref;

  private _onLocationChange = (location: Location<never>, action: HistoryAction) => {
    // Check if location really changed (might be the same location as before in case of <Prompt> "stay")
    if (!locationsAreEqual(this.location, location)) {
      runInAction(() => this._sync(location, action));
    }
  };

  private _sync(location: Location<never>, action: HistoryAction) {
    this._syncNavigationHistory(location, action);
    const currentHash = hashUtility.getCurrentHashInfo() || ({} as SerializedHash);
    const queryParameters = parseParams(window.location.search);
    Object.assign(this.legacyCurrentHash, currentHash);
    Object.assign(this, { currentHash, location, queryParameters });
  }

  private _syncNavigationHistory(location: Location<never>, action: HistoryAction) {
    if (!this._navigationHistoryEnabled) {
      Object.assign(this, { navigationHistory: [location], navigationHistoryIndex: 0, navigationAction: "unknown" } as NavigationHistoryState);
      return;
    }

    const currentState: HistoryAwareState = globalHistory.state || {};

    let navigationHistoryState: NavigationHistoryState;
    if (action === "REPLACE") {
      navigationHistoryState = this._reduceReplaceNavigation(location);
    } else if (currentState.$index == null) {
      navigationHistoryState = this._reduceImperativeNavigation(location);
      // We need to store the new index inside the current browser history state
      globalHistory.replaceState({ $index: navigationHistoryState.navigationHistoryIndex } as HistoryAwareState, null);
    } else {
      navigationHistoryState = this._reduceHistoryNavigation(action, currentState, location);
    }

    // Apply the new state to our store
    Object.assign(this, navigationHistoryState);
    // Log debug information
    log.debug(
      `${this.navigationAction} to "${dumpLocation(this.navigationHistory[this.navigationHistoryIndex])}" with index [${this.navigationHistoryIndex}]`
    );
    // Store the navigation history inside session storage
    this._storeNavigationHistoryState();
  }

  private _reduceHistoryNavigation(action: string, currentState: HistoryAwareState, location: Location<never>): NavigationHistoryState {
    const navigationAction = action === "INIT" ? "navigate" : currentState.$index > this.navigationHistoryIndex ? "forward" : "back";
    const navigationHistoryIndex = currentState.$index || 0;
    const navigationHistory = this.navigationHistory.slice();
    navigationHistory[navigationHistoryIndex] = location;
    return { navigationHistory, navigationHistoryIndex, navigationAction };
  }

  private _reduceImperativeNavigation(location: Location<never>): NavigationHistoryState {
    const navigationHistoryIndex = this.navigationHistoryIndex + 1;
    const navigationHistory = this.navigationHistory.slice(0, navigationHistoryIndex);
    navigationHistory.push(location);
    const navigationAction = "navigate";
    return { navigationHistory, navigationHistoryIndex, navigationAction };
  }

  private _reduceReplaceNavigation(location: Location<never>): NavigationHistoryState {
    const navigationHistoryIndex: number = this.navigationHistoryIndex;
    const navigationHistory: Location<never>[] = this.navigationHistory.slice();
    navigationHistory[navigationHistoryIndex] = location;
    const navigationAction: NavigationHistoryAction = "replace";
    return { navigationHistory, navigationHistoryIndex, navigationAction };
  }

  private _storeNavigationHistoryState() {
    let bytes = 0;
    const navigationHistory: StoredNavigationHistory["navigationHistory"] = [];

    // Start from latest index (we'll reverse later)
    let index = this.navigationHistory.length - 1;
    for (; index >= 0; index--) {
      const location = this.navigationHistory[index];
      // Handle session storage memory pressure when we reach 1MB
      if (bytes > 1048576 /*1MB*/) {
        break;
      }
      if (!location) {
        bytes += 4; /*0,*/
        navigationHistory.push(0);
      } else {
        // add object syntax bytes
        bytes += 6; /*{},*/
        navigationHistory.push(
          Object.fromEntries(
            Object.entries(location).filter(([k, v]) => {
              if (v) {
                // add property syntax bytes
                bytes += 2 * (k.length + v.length + 6) /*"":"",*/;
                return true;
              }
              return false;
            })
          ) as Partial<Location<never>>
        );
      }
    }

    ++index; // Restore latest stored index
    navigationHistory.reverse();

    sessionStorage.setItem(
      NAVIGATION_HISTORY_STORAGE_KEY,
      JSON.stringify({
        index,
        navigationHistory
      } as StoredNavigationHistory)
    );
  }

  private _restoreNavigationHistoryState() {
    const serialized = sessionStorage.getItem(NAVIGATION_HISTORY_STORAGE_KEY);
    if (serialized) {
      try {
        const stored: StoredNavigationHistory = JSON.parse(serialized);
        if (stored && typeof stored.index === "number" && Array.isArray(stored.navigationHistory)) {
          const navigationHistory: NavigationHistoryState["navigationHistory"] = new Array(stored.index).concat(
            stored.navigationHistory.map(
              location =>
                location
                  ? ({
                      // Restore empty properties that were stripped by `_storeNavigationHistoryState`
                      pathname: location.pathname || "",
                      hash: location.hash || "",
                      search: location.search || "",
                      key: location.key
                    } as Location<never>)
                  : undefined // in case of zeros
            )
          );
          Object.assign(this, { navigationHistory } as Pick<NavigationHistoryState, "navigationHistory">);
          log.debug("Restored history", navigationHistory.map(dumpLocation));
        } else {
          throw new Error("Stored history doesn't match expected type.");
        }
      } catch (e) {
        log.error(e.message, e);
      }
    }
  }
}

const ParameterRegex = /([^&=]+)=?([^&]*)/g;

/**
 * Parse a query string (with optional leading '?') into an object of
 * named properties. Keys that occur more than once are put in arrays.
 *
 * @param	query		The query string to parse
 * @return	the object, empty if string is empty
 */
export const parseParams = (query: string) => {
  const params = {};

  if (query) {
    if (query.substr(0, 1) == "?") {
      return parseParams(query.substr(1));
    }

    let e;
    // tslint:disable-next-line:no-conditional-assignment
    while ((e = ParameterRegex.exec(query))) {
      let key = decode(e[1]);
      let value = decode(e[2]);

      if (params.hasOwnProperty(key)) {
        let oldv = params[key];

        if (!Array.isArray(oldv)) {
          params[key] = oldv = [oldv];
        }

        oldv.push(value);
      } else {
        params[key] = value;
      }
    }
  }
  return params;
};

const decode = uriPart => decodeURIComponent(uriPart.replace(/\+/g, " "));

const history: ObservableHistory = new ObservableHistory();
export default history;
