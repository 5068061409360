// Generic date text binding, opional dateFormat gives us the format.
// Example: <span data-bind="date: observable, dateFormat: 'M/d'">...</span>
// Default format is MM/dd/yyyy
// Uses Date#toString (not Date#format, we really should clean this stuff up).
ko.bindingHandlers.date = {
  update: function(element, valueAccessor, allBindingsAccessor) {
    var format = allBindingsAccessor().dateFormat || "MM/dd/yyyy",
      value = ko.unwrap(valueAccessor());
    if (moment.isMoment(value)) {
      value = value.toDate();
    }
    if (!(value instanceof Date)) {
      value = new Date(value);
    }
    element.innerHTML = value.toString(format).escapeHTML();
  }
};

ko.bindingHandlers.shortDate = {
  init: shortDate,
  update: shortDate
};

function shortDate(element, valueAccessor, allBindingsAccessor, viewModel) {
  let unwrappedValue = ko.utils.unwrapObservable(valueAccessor());
  let unwrappedDate = moment(unwrappedValue);
  element.innerHTML = unwrappedDate.isValid() ? unwrappedDate.format("MM/DD") : "";
}

ko.bindingHandlers.mediumDate = {
  init: mediumDate,
  update: mediumDate
};

function mediumDate(element, valueAccessor, allBindingsAccessor, viewModel) {
  let unwrappedValue = ko.utils.unwrapObservable(valueAccessor());
  let unwrappedDate = moment(unwrappedValue);
  element.innerHTML = unwrappedDate.isValid() ? unwrappedDate.format("MM/DD/YYYY") : "";
}

ko.bindingHandlers.mediumDateUtc = {
  init: mediumDateUtc,
  update: mediumDateUtc
};

function mediumDateUtc(element, valueAccessor, allBindingsAccessor, viewModel) {
  let unwrappedValue = ko.utils.unwrapObservable(valueAccessor());
  let unwrappedDate = moment.utc(unwrappedValue);
  element.innerHTML = unwrappedDate.isValid() ? unwrappedDate.format("MM/DD/YYYY") : "";
}

ko.bindingHandlers.longDate = {
  init: longDate,
  update: longDate
};

function longDate(element, valueAccessor, allBindingsAccessor, viewModel) {
  let unwrappedValue = ko.utils.unwrapObservable(valueAccessor());
  let unwrappedDate = moment(unwrappedValue);
  let options = allBindingsAccessor().longDateOptions || {};

  if (!unwrappedDate.isValid()) {
    element.innerHTML = "";
    return;
  }

  let format = moment().year() != unwrappedDate.year() || options.forceYear ? "MMM DD YYYY" : "MMM DD";
  element.innerHTML = unwrappedDate.format(format);
}

ko.bindingHandlers.longDateAndTime = {
  init: longDateAndTime,
  update: longDateAndTime
};

function longDateAndTime(element, valueAccessor, allBindingsAccessor, viewModel) {
  let unwrappedValue = ko.utils.unwrapObservable(valueAccessor());
  let unwrappedDate = moment(unwrappedValue);

  if (!unwrappedDate.isValid()) {
    element.innerHTML = "";
    return;
  }

  let format = moment().year() != unwrappedDate.year() ? "MMM DD YYYY h:mm a" : "MMM DD h:mm a";
  element.innerHTML = unwrappedDate.format(format);
}

ko.bindingHandlers.day = ko.bindingHandlers.dayNoXs = {
  update: function(element, valueAccessor, allBindings) {
    var value = ko.unwrap(valueAccessor());
    var date = moment(value);
    if (isNaN(date.toDate())) {
      element.innerHTML = "";
    } else {
      var str = date.format("ddd");
      if (allBindings.get("dayNoXs")) {
        element.innerHTML = str;
      } else {
        var parts = str.split(" ");
        element.innerHTML =
          '<span class="hidden-xs">' + str + "</span>" + '<span class="visible-xs">' + parts[0].charAt(0) + " " + parts[1] + "</span>";
      }
    }
  }
};

ko.bindingHandlers.dateNoXs = {
  update: function(element, valueAccessor, allBindings) {
    var value = ko.unwrap(valueAccessor());
    var date = moment(value);
    if (isNaN(date.toDate())) {
      element.innerHTML = "";
    } else {
      var str = date.format("DD");
      if (allBindings.get("dateNoXs")) {
        element.innerHTML = str;
      } else {
        var parts = str.split(" ");
        element.innerHTML =
          '<span class="hidden-xs">' + str + "</span>" + '<span class="visible-xs">' + parts[0].charAt(0) + " " + parts[1] + "</span>";
      }
    }
  }
};

ko.bindingHandlers.month = ko.bindingHandlers.monthNoXs = {
  update: function(element, valueAccessor, allBindings) {
    var value = ko.unwrap(valueAccessor());
    var date = moment(value);
    if (isNaN(date.toDate())) {
      element.innerHTML = "";
    } else {
      var str = date.format("mmmm");
      if (allBindings.get("monthNoXs")) {
        element.innerHTML = str;
      } else {
        var parts = str.split(" ");
        element.innerHTML =
          '<span class="hidden-xs">' + str + "</span>" + '<span class="visible-xs">' + parts[0].charAt(0) + " " + parts[1] + "</span>";
      }
    }
  }
};

// Show the date as Wed 10/27 or similar.
// On xs resolution switches to W 10/27.
// Bind to a date or moment instance.
// Use dayAndDateNoXs if you don't want the xs handling.
ko.bindingHandlers.dayAndDate = ko.bindingHandlers.dayAndDateNoXs = {
  update: function(element, valueAccessor, allBindings) {
    var value = ko.unwrap(valueAccessor());
    var date = moment(value);
    if (isNaN(date.toDate())) {
      element.innerHTML = "";
    } else {
      var str = date.format("ddd MM/DD");
      if (allBindings.get("dayAndDateNoXs")) {
        element.innerHTML = str;
      } else {
        var parts = str.split(" ");
        element.innerHTML =
          '<span class="hidden-xs">' + str + "</span>" + '<span class="visible-xs">' + parts[0].charAt(0) + " " + parts[1] + "</span>";
      }
    }
  }
};
// Like above, but without xs handling, and uses the full name of the day and includes
// the year (Wednesday 10/28/2015)
ko.bindingHandlers.fullDayAndDate = {
  update: function(element, valueAccessor, allBindings) {
    var value = ko.unwrap(valueAccessor());
    var date = moment(value);
    if (isNaN(date.toDate())) {
      element.innerHTML = "";
    } else {
      element.innerHTML = date.format("dddd MM/DD/YYYY");
    }
  }
};

ko.bindingHandlers.standardDate = {
  init: standardDate,
  update: standardDate
};

function standardDate(element, valueAccessor, allBindingsAccessor, viewModel) {
  var unwrappedValue = ko.utils.unwrapObservable(valueAccessor());
  element.innerHTML = Date.standardDate(unwrappedValue, allBindingsAccessor().correctDate);
}

ko.bindingHandlers.timeShort = {
  init: timeShort,
  update: timeShort
};

function timeShort(element, valueAccessor, allBindingsAccessor, viewModel) {
  var unwrappedValue = ko.utils.unwrapObservable(valueAccessor());
  element.innerHTML = Date.timeShort(unwrappedValue, allBindingsAccessor().correctDate);
}

ko.bindingHandlers.prettyDate = {
  init: updateElementWithPrettyDate,
  update: updateElementWithPrettyDate
};

function updateElementWithPrettyDate(element, valueAccessor, allBindingsAccessor, viewModel) {
  var rawVal = valueAccessor();
  var unwrappedValue = ko.utils.unwrapObservable(rawVal);

  if (!unwrappedValue) {
    element.innerHTML = "";
  } else {
    var options = allBindingsAccessor().displayOptions || {};

    if (options.suppressTime) {
      if (CrIsDateToday(unwrappedValue)) {
        element.innerHTML = "Today";
        return;
      } else if (CrIsDateYesterday(unwrappedValue)) {
        element.innerHTML = "Yesterday";
        return;
      }
    }
    var ret = prettyDate(unwrappedValue);
    element.innerHTML = ret || CrFormatDate(unwrappedValue, "MM/dd/yyyy");
  }
}

// Note: Unlike our datepicker binding, datePickerLink expects an object with a `forObservable` property,
// e.g.:
//     <input type="button" data-bind="datePickerLink: { forObservable: yourDateObservable }" />
// And it accepts options via an `options` object on that object:
//     <input type="button" data-bind="datePickerLink: { forObservable: yourDateObservable, options: { changeMonth: true } }" />
// It does NOT support updating the options once you've bound it like the `datepicker` binding does.
ko.bindingHandlers.datePickerLink = {
  init: function(element, valueAccessor, allBindings) {
    //adapted from http://stackoverflow.com/questions/2198741/jquery-ui-datepicker-making-a-link-trigger-datepicker
    var options = ko.toJS(ko.unwrap(valueAccessor()).options) || {};
    var onSelect = options.onSelect;

    options.onSelect = function(dateText, inst) {
      valueAccessor().forObservable(options.useMoment ? moment(dateText) : dateText);
      if (onSelect) {
        onSelect.apply(this, arguments);
      }
    };
    var $dp = $("<input type='text' />")
      .hide()
      .datepicker(options)
      .insertAfter(element);

    $(element).click(function(e) {
      var value;
      if ($dp.datepicker("widget").is(":hidden")) {
        if (options.useMoment || options.setOnShow) {
          value = valueAccessor().forObservable();
          if (value) {
            $dp.datepicker("setDate", moment.isMoment(value) ? value.format("MM/DD/YYYY") : value);
          }
        }
        $dp
          .show()
          .datepicker("show")
          .hide();
        $dp.datepicker("widget").position({
          my: "left top",
          at: "right top",
          of: this
        });
      } else {
        $dp.hide();
      }
    });

    //handle disposal (if KO removes by the template binding) -Added by Matt 11/20/2014
    ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
      $($dp).datepicker("destroy");
    });
  }
};

ko.bindingHandlers.datepicker = {
  init: function(element, valueAccessor, allBindingsAccessor) {
    //initialize datepicker with some optional options
    var options = $.extend({}, ko.toJS(allBindingsAccessor.get("datepickerOptions")));
    var beforeShow;

    // (Hack cough) jQuery UI's datepicker has no "open" or "show" event, and sets
    // the z-index of the datepicker's element to 1 directly (not via a stylesheet).
    // It also reuses that element across instances, and bludgeons the z-index each
    // time it shows it. So we have to use the `beforeShow` callback, then a timeout,
    // just to make it a value higher than 1. Yeesh.
    if (options.zIndex) {
      beforeShow = options.beforeShow;
      options.beforeShow = function() {
        setTimeout(function() {
          $("#ui-datepicker-div").css("z-index", options.zIndex);
        }, 0);
        if (beforeShow) {
          return beforeShow.apply(this, arguments);
        }
      };
    }

    $(element).datepicker(options);

    //handle the field changing
    ko.utils.registerEventHandler(element, "change", function() {
      // See https://centralreach.fogbugz.com/f/cases/13172 about the setTimeout
      handleChange();
      setTimeout(function() {
        var result = handleChange();
        var evt = $.Event("afterchange");

        evt.dateValue = result;
        $(element).trigger(evt);
      }, 0);

      function handleChange() {
        var observable = valueAccessor(),
          invalid = !CrIsDateValid(element.value),
          val = invalid ? element.value : CrFormatDate(element.value, "MM/dd/yyyy");

        observable(options.useMoment ? moment(val) : val);
        return val;
      }
    });

    //handle disposal (if KO removes by the template binding)
    ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
      var $el = $(element),
        data = $el.data("datepicker");

      // element reference was causing issue with memory being released
      if (data) {
        data.input = null;
      }

      $(element).datepicker("destroy");
    });
  },
  update: function(element, valueAccessor, allBindingsAccessor) {
    var value = ko.utils.unwrapObservable(valueAccessor()),
      options = ko.toJS(allBindingsAccessor.get("datepickerOptions"));

    // The options may have changed, if they're observable
    if (options) {
      $(element).datepicker("option", options);
    }
    if (moment.isMoment(value)) {
      // '', null, etc. (see below) return false, not an error
      value = value.toDate();
    }
    if (value == "" || value == null || !CrIsDateValid(value)) {
      $.datepicker._clearDate(element);
    } else {
      if (!CrAreDatesEqual($(element).datepicker("getDate"), value)) {
        $(element).datepicker("setDate", CrFormatDate(value, "MM/dd/yyyy"));
      }
    }
  }
};
