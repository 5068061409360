define("widgets/cr-select", ["framework/globalUtils/koNodePreprocessor", "app/util/elasticLookupMapper", "app/util/transport"], function(
  widgets,
  ElasticLookupMapper,
  transportModule
) {
  "use strict";

  // Usage:
  //
  // The cr-select widget supports various options (list below), which may be specified in the
  // HTML and/or on the viewmodel. If both, the HTML wins.
  //
  // ==== Basic HTML:
  //
  //      <cr-select using="vmname"></cr-select>
  //
  // ...where vmname is the name of the viewmodel to use. The `using` attribute is required.
  // Options, if desired, are specified in a separate `options` attribute:
  //
  //      <cr-select using="vmname"
  //                 options="call: 'niftymod.loadlist', idProperty: 'value'">
  //      </cr-select>
  //
  // ...or on the viewmodel.
  //
  // The viewmodel can be just an observable or observableArray (for multi-select) if defaults
  // are used (or any needed options are in the HTML), or it can be an object with a `val`
  // property and zero or more properties for the options below.
  //
  // ==== Options:
  //
  // The cr-select widget supports the following options. The only requirement is one of
  // `choices`, `search`, or `call` just be supplied; all others have defaults.
  // For the defaults, see "defaults" in the code below.
  //
  // val              The observable/observableArray to contain the value selected in the
  //                  cr-select. If not given, the viewmodel itself is used directly.
  //                  Note that the this receives objects, not IDs/strings.
  //                  If you've set up KO validation on this observable, cr-select will show
  //                  validation results. See also `invalidClass` and `validClass` below.
  // syncSource       A VM that we sync individual properties with. If not given but
  //                  `syncMap` is, we use `val`.
  // syncMap          Map of discrete properties to sync, keyed by the name of the object in
  //                  the select box, value is the name of the observable on the sync source to
  //                  sync with.
  // search           A function to supply the items that should be presented. Receives a single
  //                  argument, an object with `search` and `callback` properties. `search` is
  //                  the substring to match (in all lower case), `callback` is the function to
  //                  call back with an array of matching items. The callback may be synchronous
  //                  or asynchronous. If this is given, `choices` and `call` are ignored.
  // choices          An array/observable array of all of the items to offer in the list. If this
  //                  is given, `call` and `callParams` are ignored even if present.
  //                  This should be an array of objects.
  // call             The module and action to use to query the server for the list of items to
  //                  offer, in the form "module.action" (e.g., "dashboard.loadsomelist"). The
  //                  query will include the parameters "search" and "searchTerm", both of which
  //                  being the substring to match (both are sent for legacy reasons).
  // callParams       If given, an object of parameters to include when using `call`. ("search"
  //                  and "searchTerm" are added automatically.)
  // callCache        The ExpiringCache instance to use for caching results; by default each
  //                  cr-select gets its own. If you supply this, it's up to you to ensure that
  //                  you only share the cache with selects using the same call!
  // callCacheTime    The number of milliseconds to keep previous call results in case they're
  //                  used again (0 = never cache results); if the same query is made within this
  //                  interval (even if a different one is made in-between), the call is
  //                  satisfied by the cached version. Ignored if `callCache` is supplied.
  // callDelay        The number of milliseconds to wait before issuing the call, to allow the
  //                  user to continue typing.
  // preprocess       When using `call`, you can supply this function to pre-process the response
  //                  from the server as a whole, prior to those items being offered in the box.
  //                  It receives the entire response as an argument (not just the list).
  // prepItem         If given, this function is called to pre-process an entry *after* it's
  //                  selected by the user, *before* it's put in the value observable. If
  //                  `prepItem` is a generic function, it's called with the item and expected
  //                  to return the (updated) item; if it's a viewmodel constructor, the
  //                  constructor's `createFromResponse` function is used.
  // selecting        If given, this function is called after a choice has been selected but before
  //                  any modification has been made to the selection. This is called BEFORE prepItem.
  //                  This function is called with the selected item. This function requires a truthy/falsy
  //                  return value, true = continue with selection, false = reject selection.
  // removing         Same as selecting except when input is being removed
  // idProperty       The name of the property on item objects that contains the item's unique ID
  //                  in the list.
  // textProperty     The name of the property on item objects that contains the item's display
  //                  text (label, etc.)
  // listProperty     When using 'call', this is the name of the property on the response with
  //                  the array of matching items.
  // detectBlanks     (single mode only) true to have the select treat `{Id: -1, Name: ""}` objects
  //                  as `null`, false not to. See blankDetector below for details on what "blank"
  //                  means by default.
  // blankDetector    a function that's called if detectBlanks is true to detect if a given item
  //                  is "blank". The default version is to consider something blank if its
  //                  "text" property is `""` or `undefined` and its `id` property is `-1`,
  //                  `"-1"`, `""`, or `undefined`.
  //                  Signature: `blankDetector(item, idProperty, textProperty)`
  //                  where `item` is the item, `idProperty` is the name of the "id" property,
  //                  and `textProperty` is the name of the "text" property.
  //                  Should return a truthy value for effectively-blank entries, falsey otherwise.
  // multi            false for having just one selected option; true to support multiple.
  // maxSelections    In multi-mode, the max number of selections allowed (default = unlimited).
  // searching        Text to display while waiting for the list of matching items to be
  //                  populated; can be a function or a string. Function receives no arguments
  //                  and must return a string (will be interpreted as HTML).
  // noneFound        Text to display when the list of matching items is empty; can be a
  //                  function or a string. Function receives the search term as its only arg,
  //                  and must return a string (will be interpreted as HTML)
  // minInputLength   Minimum number of characters the user must type before we start showing
  //                  suggestions.
  // closeOnSelect    When in multi mode, close the list when the user chooses one.
  // placeholder      Placeholder text for when nothing is selected.
  // allowClear       Even in single-select mode, allow the user to clear the selection (going
  //                  back to a "nothing selected" state). This requires a placeholder, so if
  //                  none is supplied, a default placeholder is used.
  // validClass       If hooked into KO validation, this is the class we add to the cr-select
  //                  element when the field is modified and _valid_.
  // invalidClass     If hooked into KO validation, this is the class we add to the cr-select
  //                  element when the field is modified and _invalid_.
  // listTemplate     The template to use for rendering items in the list.
  // tagTemplate      The template to use for rendering selected items in a multi-select list (
  //                  e.g., "tags").
  // enable           an observable or boolean value to enable or disable the select2
  //                  by default this value is set to true
  // enableAdd        an observable or boolean value to enable or disable the ability to *add*
  //                  entries (useful for multi-selects where you can only delete)
  //                  by default this value is set to true
  //
  // ==== Item objects:
  //
  // An item object is expected to have two properties: An ID (typically `id`), and text
  // (typically `text`). The names of these properties can be controlled via the `idProperty`
  // and `textProperty` options for cr-select.
  // If an item should be on a list but not allow the user to remove it, the item may have a
  // third property, `locked`, set to `true`.
  // All other properties on the objects are ignored.
  //
  // ==== Events:
  //
  // change           When a value is selected (or added or removed, in the case of multi-
  //                  select), the standard `change` event is raised.

  const Api = transportModule.default.api;

  // List of option names, and our default options
  var defaults = {
    idProperty: "id",
    textProperty: "text",
    listProperty: "list",
    // multi defaults based on whether the target observable is an observable array
    searching: "Searching...",
    noneFound: "No matches found.",
    // minInputLength defaults to 2 for server calls, 0 for local array
    // No placeholder default
    allowClear: false,
    callCacheTime: 15000, // in milliseconds
    callDelay: 200, // "  "
    closeOnSelect: true,
    validClass: "",
    invalidClass: "cr-invalid",
    detectBlanks: true, // ignored (and set false) in multi mode
    blankDetector: standardBlankDetector,
    enable: true,
    enableAdd: true
  };

  // Our widget handler
  widgets.addElementHandler({
    type: "cr-select",
    process: function($node) {
      var $rv, bind, using, options;

      using = $node.attr("using");
      if (!using) {
        throw "cr-select requires the 'using' attribute";
      }
      bind = "crSelect: { using: " + using + ", options: { " + ($node.attr("options") || "") + " } }";

      $rv = widgets.mapAttributes($node, $('<input type="hidden">'), ["using"]);
      $rv = $rv.attr("data-bind", bind);
      return $rv;
    }
  });

  // Our KO binding
  ko.bindingHandlers.crSelect = {
    init: crSelectInit,
    update: crSelectUpdate
  };

  // Our jQuery plugin
  $.fn.crSelect = crSelectPlugin;
  function crSelectPlugin(method, arg) {
    // Whitelist methods, rename as necessary
    switch (method) {
      case "open":
      case "close":
        this.select2(method);
        break;
      case "disabled":
        this.select2("enable", !arg);
        break;
      case "enable":
      case "readonly":
        this.select2(method, arg);
        break;
    }
  }

  // KO init for our binding handler
  function crSelectInit(element, valueAccessor) {
    var options,
      s2options,
      $element = $(element),
      validationSubscriptions,
      syncs,
      valChanged,
      valSubscription,
      enableSub,
      enableAddSub;

    options = prepOptions($element, valueAccessor);
    s2options = createSelect2Options(options);

    var placeholderRaw = options.placeholder,
      placeholder = typeof placeholderRaw === "undefined" ? "Search" : ko.unwrap(placeholderRaw);

    if (options.val.isValid) {
      // isValid is added by KO validation, so if it's there, hook it up
      validationSubscriptions = setupKOValidation($element, options, s2options);
    }

    if (placeholderRaw && placeholderRaw.subscribe) {
      $.extend(validationSubscriptions, {
        placeholder: placeholderRaw.subscribe(function(val) {
          placeholder = val;
          setPlaceHolder();
        })
      });
    }

    $element.select2(s2options).on("change", function() {
      var data;

      data = $element.select2("data");

      if (options.prepItem) {
        if ($.isArray(data)) {
          data = data.map(function(entry) {
            return options.prepItem(entry);
          });
        } else if (data !== null) {
          data = options.prepItem(data);
        }
      }
      options.val(data);
      setPlaceHolder();
    });

    if (options.selecting) {
      $element.on("select2-selecting", function(e) {
        var success = options.selecting(e.object);
        // if our selecting function returns false
        // we don't want to proceed with the selection
        if (!success) e.preventDefault();
      });
    }
    if (options.removing) {
      $element.on("select2-removing", function(e) {
        var success = options.removing(e.choice);
        // if our removing function returns false
        // we don't want to proceed with the selection
        if (!success) e.preventDefault();
      });
    }

    // first set the initial state of the select2
    // doesn't look like we can include the enable in the select2 init
    $element.select2("enable", options.enable());
    enableSub = options.enable.subscribe(function(val) {
      $element.select2("enable", !!val);
    });

    enableAddSub = options.enableAdd.subscribe(function(val) {
      updateEnableAdd(val);
    });
    updateEnableAdd(options.enableAdd());

    valSubscription = options.val.subscribe(function(newValue) {
      setSelect2Data($element, newValue);
      if (valChanged) {
        valChanged(newValue);
      }
    });

    // Note: This must be *after* the subscription options.val above.
    if (options.syncMap) {
      valChanged = setupSync();
    }

    ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
      validationSubscriptions = disposeAll(validationSubscriptions);
      syncs = disposeAll(syncs);
      valSubscription = disposeOne(valSubscription);
      enableSub = disposeOne(enableSub);
      enableAddSub = disposeOne(enableAddSub);

      var select2 = $element.data("select2");
      if (select2) {
        select2.opts.element.show = $.noop;
      }
      $element.select2("destroy");

      options.destroyed = true;
      options = s2options = $element = element = valueAccessor = undefined;
    });

    setPlaceHolder();

    function setupSync() {
      var syncPropNames, syncSource, syncMap, handlingValChanged, handlingSyncChanged, handlingValPropChanged;

      handlingValChanged = handlingSyncChanged = handlingValPropChanged = 0;
      syncMap = options.syncMap();
      syncPropNames = Object.keys(syncMap);

      // Get sync source, flag when it changes
      syncSource = options.syncSource || options.val;
      syncs = {
        syncSource: syncSource.subscribe(syncSourceChanged)
      };
      syncSourceChanged();

      // Hook up monitoring of observable properties on 'val' that we're supposed to sync
      setupValPropSyncs(options.val());

      return valChanged;

      function setupValPropSyncs(valobj) {
        Object.keys(syncs).forEach(function(key) {
          if (key.substring(0, 5) === "__vp_") {
            disposeAndDelete(syncs, key);
          }
        });

        // We only do this for non-array vals
        if (valobj && !$.isArray(valobj) && typeof valobj === "object") {
          syncPropNames.forEach(function(selectName) {
            if (ko.isObservable(valobj[selectName])) {
              syncs["__vp_" + syncMap[selectName]] = valobj[selectName].subscribe(valPropChanged.bind(undefined, selectName));
            }
          });
        }
      }

      // The sync source changed, subscribe to the synced properties and update our value
      function syncSourceChanged() {
        var source = ko.unwrap(syncSource);
        syncPropNames.forEach(function(selectName) {
          var syncName = syncMap[selectName];
          disposeOne(syncs["__sn_" + syncName]);
          syncs["__sn_" + syncName] = source[syncName].subscribe(syncChanged);
        });
        syncChanged();
      }

      // An observable property of the val that we're syncing changed
      function valPropChanged(selectName, newValue) {
        var source, syncName;
        try {
          ++handlingValPropChanged;
          source = ko.unwrap(syncSource);
          syncName = syncMap[selectName];
          source[syncName](ko.unwrap(newValue));
        } finally {
          --handlingValPropChanged;
        }
      }

      // The val changed; sync the target properties from the cr-select val
      function valChanged(newValue) {
        var source;

        ++handlingValChanged;
        try {
          // Get the new unwrapped value
          newValue = ko.unwrap(newValue);

          // Make sure we're watching it properly
          setupValPropSyncs(newValue);

          // Only update synced properties if that's not why we're being called
          if (!handlingSyncChanged) {
            source = ko.unwrap(syncSource);
            syncPropNames.forEach(function(selectName) {
              var syncName = syncMap[selectName];
              source[syncName](newValue ? ko.unwrap(newValue[selectName]) : "");
            });
          }
        } finally {
          --handlingValChanged;
        }
      }

      // A sync'd property changed; sync the cr-select val from the target properties
      function syncChanged() {
        var source,
          current,
          obj,
          allAreBlank = true,
          changed = false;

        // Ignore this if it happens while we're handling a change to `val`
        if (handlingValChanged || handlingValPropChanged) {
          return;
        }
        ++handlingSyncChanged;
        try {
          source = ko.unwrap(syncSource);
          current = options.val();
          obj = {};
          syncPropNames.forEach(function(selectName) {
            var syncName = syncMap[selectName];
            var val = ko.unwrap(source[syncName]);
            obj[selectName] = val;
            if (val) {
              allAreBlank = false;
            }
            if (!current || ko.unwrap(current[selectName]) !== val) {
              changed = true;
            }
          });
          if (changed) {
            if (allAreBlank) {
              obj = null;
            } else if (options.prepItem) {
              obj = options.prepItem(obj);
            }
            options.val(obj);
          }
        } finally {
          --handlingSyncChanged;
        }
      }
    }

    function setPlaceHolder() {
      $element &&
        placeholder &&
        $element
          .parent()
          .find("input.select2-input")
          .attr("placeholder", placeholder);
    }

    function updateEnableAdd(flag) {
      // Show/hide the input
      $element
        .select2("container")
        .find(".select2-search-field")
        .toggleClass("no-add", !flag);
      if (flag) {
        // Enable opening the drop-down
        $element.off("select2-opening.crselect");
      } else {
        // Disable opening the drop-down
        $element.on("select2-opening.crselect", false);
      }
    }
  }

  function disposeAndDelete(obj, prop) {
    disposeOne(obj[prop]);
    delete obj[prop];
  }

  function disposeOne(sub) {
    if (sub) {
      try {
        sub.dispose();
      } catch (e) {}
    }
    return undefined;
  }

  function disposeAll(obj) {
    if (obj) {
      Object.keys(obj).forEach(function(key) {
        disposeOne(obj[key]);
        delete obj[key];
      });
    }
    return undefined;
  }

  function setupKOValidation($element, options, s2options) {
    var valspan, subscriptions;

    valspan = $('<span class="error" data-bind="validationMessage: val"></span>');
    valspan.insertAfter($element);
    ko.applyBindings({ val: options.val }, valspan[0]);
    subscriptions = {
      modified: options.val.isModified.subscribe(validUpdate),
      valid: options.val.isValid.subscribe(validUpdate)
    };

    function validUpdate() {
      var container = $element.select2("container"),
        isModified = options.val.isModified(),
        isValid = options.val.isValid(),
        validClass = options.validClass();
      container.toggleClass(options.invalidClass(), isModified && !isValid);
      if (validClass) {
        container.toggleClass(validClass, isModified && isValid);
      }
    }

    return subscriptions;
  }

  // KO update for our binding handler
  function crSelectUpdate(element, valueAccessor) {
    var basics = getVmAndVal(valueAccessor());
    setSelect2Data($(element), basics.val);
  }

  // Our standard "blank" detector
  function standardBlankDetector(item, idProp, textProp) {
    if (
      item &&
      (item[textProp] === "" || typeof item[textProp] === "undefined") &&
      (item[idProp] === -1 || item[idProp] === "-1" || item[idProp] === "" || typeof item[idProp] === "undefined")
    ) {
      return true;
    }
    return false;
  }

  // Actually set the data in the select2
  function setSelect2Data($element, value) {
    var data, options, idProp, textProp;

    data = ko.toJS(value);
    if (data) {
      options = $element.data("cr-select-options");
      if (options && options.detectBlanks()) {
        if (options.blankDetector(data, options.idProperty(), options.textProperty())) {
          data = null;
        }
      }
    }
    $element.select2("data", data);
  }

  function getVmAndVal(params) {
    var rv = {};
    rv.vm = params.using;
    rv.val = (params.options && params.options.val) || rv.vm.val;
    if (rv.val) {
      if (!ko.isObservable(rv.val)) {
        throw "crSelect requires that 'val' be observable";
      }
    } else {
      if (!ko.isObservable(rv.vm)) {
        throw "crSelect requires the 'val' option, or that the object referenced by 'using' be observable";
      }
      rv.val = rv.vm;
      rv.vm = undefined;
    }
    return rv;
  }

  // crSelectInit subroutine: Get and prep the options
  function prepOptions($element, valueAccessor) {
    var params, basics, options;

    // Get our params and VM, handle defaulting the target
    params = valueAccessor();
    basics = getVmAndVal(params);

    // Get options, with defaults, and make sure everything is an
    // observable to keep things simple. `extend` will ignore `basics.vm`
    // if it's `undefined`.
    options = $.extend({}, defaults, basics.vm, params.options);
    options.val = basics.val;
    Object.keys(options).forEach(function(key) {
      var val = options[key];
      if (typeof val !== "function") {
        options[key] = ko.observable(val);
      }
    });

    // If we have `prepItem` and it's a viewmodel constructor, grab its createFromResponse
    if (options.prepItem && options.prepItem.createFromResponse) {
      options.prepItem = options.prepItem.createFromResponse;
    }

    // Get a search function if we don't have one
    if (!options.search) {
      if (options.choices && typeof options.choices().length === "number") {
        options.search = crSelectSearchChoices;
      } else if (typeof ko.unwrap(options.call) === "string") {
        options.search = crSelectSearchCall;
        options.call = options.call().split(".");
        if (options.call.length !== 2) {
          throw "Error: crSelect's 'call' option must be in the form 'channelId.action'";
        }
        options.call = {
          channelId: options.call[0],
          action: options.call[1]
        };
        if (options.callCache) {
          options.resultCache = ko.unwrap(options.callCache);
        } else if (options.callCacheTime() !== 0) {
          options.resultCache = new cr.util.ExpiringCache(options.callCacheTime());
        }
        if (!options.callParams) {
          options.callParams = ko.observable({});
        }
      } else {
        throw "Error: crSelect needs one of these options: 'choices' [array or array-like], 'call' [string], or 'search' [function]";
      }
    }

    // Misc computed defaults
    if (!options.hasOwnProperty("multi")) {
      options.multi = ko.observable($.isArray(options.val()));
    }
    if (!options.hasOwnProperty("minInputLength")) {
      options.minInputLength = ko.observable(options.call ? 2 : 0);
    }
    if (options.allowClear() && (!options.placeholder || !options.placeholder())) {
      options.placeholder = ko.observable("Click to select...");
    }

    // In multi mode, we don't do blank detection
    if (options.multi()) {
      options.detectBlanks(false);
    }

    // Remember the options on the element
    $element.data("cr-select-options", options);

    return options;

    // ==== Nested functions

    // Search using options.choices
    function crSelectSearchChoices(req) {
      var search = req.search.toLowerCase();
      var results = [];
      search = search.toLowerCase();
      $.each(options.choices(), function(index, item) {
        var matches = crMatches(item, search);
        Array.prototype.push.apply(results, matches);
      });
      req.callback(results);
    }

    // if there is an optgroup we also need to search children
    function crMatches(item, search) {
      search = search.toLowerCase();

      var result = [];
      if (!search || item[options.textProperty()].toLowerCase().indexOf(search) >= 0) {
        result.push(item);
        return result;
      }

      (item.children || []).forEach(function(c) {
        Array.prototype.push.apply(result, crMatches(c, search));
      });
      return result;
    }

    // Search using cr.transmitRequest
    function crSelectSearchCall(req) {
      var search = req.search.toLowerCase(),
        params,
        results,
        cacheKey;

      // Cancel pending search
      if (options.callTimer) {
        clearTimeout(options.callTimer);
        options.callTimer = 0;
      }

      // Get the params
      params = $.extend({}, options.callParams(), {
        search: search,
        searchTerm: search
      });
      cacheKey = getCacheKey(params);

      // Cached?
      results = options.resultCache && options.resultCache.get(cacheKey);
      if (results) {
        // Always copy the results so that the cached copy isn't updated
        req.callback($.extend(true, [], results));
        return;
      }

      // Queue request
      options.callTimer = setTimeout(function() {
        if (options.destroyed || options.callTimer === 0) {
          return;
        }
        options.callTimer = 0;

        // check if we have a matching elastic search lookup and use that instead
        // otherwise, fall back to the 'legacy' lookup
        if (ElasticLookupMapper.default.hasElasticEquivalent(options.call.channelId + "." + options.call.action)) {
          // translate to elastic
          const apiTier = ElasticLookupMapper.default.mapMidTierToApi(options.call.channelId + "." + options.call.action);

          // unwrap it
          options.listProperty(apiTier.list);
          options.textProperty(apiTier.display);
          options.idProperty(apiTier.id);

          // hit the api
          Api.get(`search/lookups?query=${encodeURIComponent(search)}&${apiTier.source}`).then(resp => {
            results = resp[options.listProperty()].map(item => {
              return $.extend(item, { id: item[options.idProperty()] || item.id || item.Id, Id: item[options.idProperty()] || item.id || item.Id });
            });
            req.callback(results);
          });
        } else {
          cr.transport.transmitRequest(options.call.channelId, options.call.action, params, function(response) {
            var results;

            if (options.preprocess) {
              options.preprocess(response);
            }
            results = response[options.listProperty()];
            if (!results) {
              throw "crSelect: Error, '" +
                options.listProperty() +
                "' is blank or missing in server response to '" +
                options.call.channelId +
                "." +
                options.call.action +
                "'";
            }
            if (options.resultCache) {
              // Copy the results when caching to avoid the cache getting modified
              options.resultCache.put(cacheKey, $.extend(true, [], results));
            }
            req.callback(results);
          });
        }
      }, options.callDelay());
    }
  }

  function getCacheKey(params) {
    // Dirty, but good enough.
    // It's sensitive to the order of the keys in the map (an order which
    // officially doesn't exist, but engines are consistent within themselves),
    // but then, equivalent searches are very likely to have the same order of
    // keys.
    return JSON.stringify(params);
  }

  // Based on the given cr-select options, create the select2 options
  function createSelect2Options(options) {
    var s2options;

    s2options = {
      minimumInputLength: options.minInputLength(),
      allowClear: options.allowClear(),
      multiple: options.multi(),
      query: crSelectQuery,
      closeOnSelect: options.closeOnSelect()
    };

    if (options.maxSelections) {
      s2options.maximumSelectionSize = options.maxSelections;
    }
    if (options.placeholder) {
      s2options.placeholder = options.placeholder();
    }
    if (options.idProperty() !== "id") {
      s2options.id = function(item) {
        return item[options.idProperty()];
      };
    }
    if (options.textProperty) {
      s2options.formatResult = s2options.formatSelection = function(item, container) {
        return item[options.textProperty()];
      };
    }
    if (options.searching) {
      if (ko.isObservable(options.searching)) {
        s2options.formatSearching = function() {
          return options.searching();
        };
      } else {
        s2options.formatSearching = options.searching;
      }
    }
    if (options.noneFound) {
      if (ko.isObservable(options.noneFound)) {
        s2options.formatNoMatches = function() {
          return options.noneFound();
        };
      } else {
        s2options.formatNoMatches = options.noneFound;
      }
    }
    if (options.width) {
      s2options.width = options.width();
    }
    if (options.listTemplate) {
      s2options.formatResult = options.listTemplate;
    }
    if (options.createSearchChoice) {
      s2options.createSearchChoice = options.createSearchChoice;
    }
    if (options.formatSelectionCssClass) {
      s2options.formatSelectionCssClass = options.formatSelectionCssClass;
    }

    return s2options;

    // ==== Nested functions

    // The 'query' function we give select2
    function crSelectQuery(query) {
      options.search({ search: query.term, callback: callback, context: query.element.context });
      function callback(results) {
        query.callback({ results: results });
      }
    }
  }

  // We don't expose anything directly
  return null;
});
