import { inject, Provider } from "mobx-react";
import React, { Component } from "react";
import { activateStore, disposeStore, getStoreName, instantiateStore } from "./helpers";
import rootStore from "./rootStore";
import { IStore, StoreConstructor } from "./store";

/**
 * Injects an instance of a specific Store into a React component's `props`.
 *
 * Notes:
 * * The name of the injected store will be the Camel-cased name of the `StoreType`.
 *  e.g. `TasksStore` becomes `tasksStore`
 * * Stores passed via props will take precendence and not be overwritten
 * * Stores will be activated (`store.activate()`) every time they are injected into
 *   a component (regardless) of whether they already exist or have just been created
 *
 * **IMPORTANT:**
 * When used with the MobX `@observer` decorator, be sure to apply
 * `@injectStore` _before_ `@observer`
 *
 * @param  {...StoreConstructor[]} StoreTypes 1 to many Store types to be injected into the component's props
 *
 @example 
 ```
 @injectStore(TasksStore, ContactsStore)
 @observer
 class TaskList extends Component<{ tasksStore?: TasksStore, contactsStore?: ContactsStore }>
 ```
 
 @example
 ```
 class ClaimsStore extends Store {
  @injectStore(ContactsStore) private contactsStore: ContactsStore;
 }
 ```
 */
export function injectStore(...StoreTypes: StoreConstructor<any>[]) {
  if (StoreTypes == null || StoreTypes.filter(x => x != null).length == 0) {
    throw Error("@injectStore requires at least one StoreType");
  }

  return (target: any, propertyName?: string) => {
    // property decorator
    if (typeof propertyName == "string") {
      if (StoreTypes.length > 1) {
        throw Error("@injectStore: Only one store type may be injected into a property");
      }

      injectProperty(StoreTypes[0], target, propertyName);

      return;
    }

    // class decorator
    if (target.prototype.isReactComponent) {
      return injectReactComponentClass(StoreTypes, target);
    } else {
      return injectStoreClass(StoreTypes, target);
    }
  };
}

function injectProperty(StoreType: StoreConstructor<any>, store, propertyName) {
  let instance = rootStore.getStore(StoreType);

  if (instance == null) {
    instance = instantiateStore(StoreType, store, getStoreName(store));
    activateStore(instance);
  }

  Object.defineProperty(store, propertyName, {
    configurable: true,
    enumerable: true,
    get() {
      return instance;
    }
  });
}

function injectStoreClass(StoreTypes: StoreConstructor<any>[], target: any) {
  return class extends target {
    constructor(...args) {
      super(...args);
      StoreTypes.forEach(type => injectProperty(type, this, getStoreName(type)));
    }
  };
}

function injectReactComponentClass(StoreTypes: StoreConstructor<any>[], ComponentClass) {
  // store mapping function to be passed to MobX's @inject() below
  const storeMapper = componentName => (mobxStores, props: StoreInjectorProps) => {
    const ownStores = {};
    const injectedStores = [];

    const getStore = <TStore extends IStore>(StoreType: StoreConstructor<TStore>): TStore => {
      const storeName = getStoreName(StoreType);

      // look for the store on the props, Context (i.e. mobxStores), or Root Store, in that order
      return props[storeName] || mobxStores[storeName] || rootStore.getStore(StoreType);
    };

    StoreTypes.forEach(StoreType => {
      const storeName = getStoreName(StoreType);

      let store = getStore(StoreType);

      // if the store doesn't already exist, create a new one
      if (store == null) {
        store = instantiateStore(StoreType, { getStore }, componentName);

        // register the instantiated store with the current context, but only if it's not a global store
        if (!StoreType.isPersistent) {
          ownStores[storeName] = store;
        }
      }

      // add the stores to the props by name so they're available to the wrapped component
      props[storeName] = store;

      // keep track of all the stores we've injected
      injectedStores.push(store);
    });

    // pass the injected stores to the HOC so they can be activated on mount
    props.injectedStores = injectedStores;

    // pass the stores that we've created to the HOC so they can be disposed on umount
    props.ownStores = ownStores;

    return props;
  };

  interface StoreInjectorProps {
    injectedStores: IStore[];
    ownStores: { [key: string]: IStore };
  }

  @inject(storeMapper(ComponentClass.name))
  class StoreInjector extends Component<StoreInjectorProps, never> {
    get ownStores() {
      const { ownStores } = this.props;
      return Object.keys(ownStores).map(key => ownStores[key]);
    }

    componentWillMount() {
      const { injectedStores } = this.props;

      injectedStores.forEach(activateStore);
    }

    componentWillUnmount() {
      this.ownStores.forEach(disposeStore);
    }

    render() {
      // don't pass the injectedStores and ownStores props through to the wrapped component
      const { injectedStores, ownStores, ...props } = this.props;
      return (
        <Provider {...ownStores}>
          <ComponentClass {...props} />
        </Provider>
      );
    }
  }

  return StoreInjector as any;
}
