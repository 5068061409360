export type LogLevel = keyof ILogger;

// tslint:disable-next-line:interface-name
interface ILogger {
  trace: (...args) => void;
  debug: (...args) => void;
  info: (...args) => void;
  warn: (...args) => void;
  error: (...args) => void;
  network: (...args) => void;
  log: (level: LogLevel, ...args) => void;
}

const isProduction = process.env.NODE_ENV === "production";
const LoggerContextsStorageKey = "logger:contexts";

/**
 * Helper class to provide contextual log messages, as well as
 * extensibility point for e.g. logging to a server.
 */
export class Logger implements ILogger {
  private static _muted = false;
  private static readonly _contexts = new Set<string>();
  private static readonly _mutedContexts = new Set<string>();
  private static _activeContexts = new Set<string>((localStorage.getItem(LoggerContextsStorageKey) || "app").split(","));

  protected constructor(protected readonly context: string = null) {}

  null = () => null;
  trace = (...args) => this.log("debug", ...args);
  debug = (...args) => this.log("debug", ...args);
  info = (...args) => this.log("info", ...args);
  warn = (...args) => this.log("warn", ...args);
  error = (...args) => this.log("error", ...args);
  network = this.debug;
  log = (level: LogLevel, ...args) => {
    if (level !== "error" && !Logger.isActive(this.context)) {
      return;
    }

    const params = this.context ? [].concat(`[${this.context}]`, args) : args;
    console[level].apply(console, params);
  };

  static create(context: string): Logger & ((level: LogLevel, ...args) => void) {
    const logger = new Logger(context);
    const log = logger.log;
    Object.assign(log, logger);
    return log as any;
  }

  static listenTo(context: string) {
    this._contexts.add(context);
    this.recalculateActive();
  }

  static mute(toggle?: boolean);
  static mute(context: string);
  static mute(contextOrToggle: string | boolean) {
    if (contextOrToggle == null || typeof contextOrToggle === "boolean") {
      this._muted = contextOrToggle == null ? true : !!contextOrToggle;
    } else {
      this._mutedContexts.add(contextOrToggle);
    }

    this.recalculateActive();
  }

  private static isActive(context: string) {
    return !this._muted && (this._mutedContexts.size === 0 && this._activeContexts.size === 0 ? true : Logger._activeContexts.has(context));
  }

  private static recalculateActive() {
    if (this._contexts.size === 0 && this._mutedContexts.size === 0) {
      return true;
    }

    const active = new Set(this._contexts);
    this._mutedContexts.forEach(key => active.delete(key));

    this._activeContexts = active;

    const cachedContexts = this._muted ? "" : [...active].join(",");
    localStorage.setItem(LoggerContextsStorageKey, cachedContexts);
  }
}

/**
 * Extends a class with logging functionality by adding a `log` [@link Logger] property
 *
 * @param Class the class to decorate
 *
 *  @see {@link Logger}
 */
export const logger = Class => {
  Class.prototype.log = Logger.create(Class.name);
};

// FOR DIAGNOSTIC USE ONLY -- DO NOT REFERENCE FROM PRODUCTION CODE!
(window as any).CRLogger = Logger;

declare global {
  // True, this isn't literally what's happening - you only get .log() if you use @logger
  // but this is the compromise until decorators are truly supported
  interface Object {
    /** Log a message with a given log level */
    log: Logger & ((level: LogLevel, ...args) => void);
  }
}
