const labelsContent = require("shared/nestedLabelsContent.htm");
const labelsTemplate = require("shared/nestedLabelsTemplate.htm");
const labelsContentAlt = require("shared/nestedLabelsContentAlt.htm");
const labelsTemplateAlt = require("shared/nestedLabelsTemplateAlt.htm");

const widgets = require("framework/globalUtils/koNodePreprocessor");

widgets.addElementHandler({
  type: "nested-labels-template-alt",
  process: function() {
    return labelsTemplateAlt;
  }
});

widgets.addElementHandler({
  type: "nested-labels-content-alt",
  process: function($node) {
    return widgets.mapAttributes($node, labelsContentAlt);
  }
});

widgets.addElementHandler({
  type: "nested-labels-template",
  process: function($node) {
    return labelsTemplate
      .replace("#{data-hash}", 'data-hash="' + ($node.attr("data-hash") || "") + '"')
      .replace("#{data-click}", $node.attr("data-label-click") ? 'data-click="' + $node.attr("data-label-click") + '"' : "");
  }
});

widgets.addElementHandler({
  type: "nested-labels-content",
  process: function($node) {
    var $labelsContentAdjusted = $(labelsContent);

    $labelsContentAdjusted.find(".private-labels-header").attr("data-bind", "text: " + ($node.attr("private-header") || "privateLabelsHeader"));
    $labelsContentAdjusted.find(".public-labels-header").attr("data-bind", "text: " + ($node.attr("public-header") || "publicLabelsHeader"));

    if ($node.is("[deferred-load]")) {
      $labelsContentAdjusted.find('[href="#collapseTagsPublic"]').attr("data-click", "loadLabelsIfNotAlreadyLoaded");
      $labelsContentAdjusted.find('[href="#collapseTagsPrivate"]').attr("data-click", "loadLabelsIfNotAlreadyLoaded");
    }

    if ($node.attr("id-adjust")) {
      var adjustWith = $node.attr("id-adjust");
      $labelsContentAdjusted.find('[href="#collapseTagsPublic"]').attr("href", "#collapseTagsPublic" + adjustWith);
      $labelsContentAdjusted.find('[href="#collapseTagsPrivate"]').attr("href", "#collapseTagsPrivate" + adjustWith);

      $labelsContentAdjusted.find("#collapseTagsPublic").attr("id", "collapseTagsPublic" + adjustWith);
      $labelsContentAdjusted.find("#collapseTagsPrivate").attr("id", "collapseTagsPrivate" + adjustWith);
    }

    //tweak the result to pre-expand the labels
    if ($node.is("[data-expand]")) {
      var $labelsContent = $($labelsContentAdjusted);
      $labelsContent.find('a[data-toggle="collapse"].collapsed').removeClass("collapsed");
      $labelsContent
        .find("div.panel-collapse.collapse")
        .removeClass("collapse")
        .addClass("in");
      return widgets.mapAttributes($node, $labelsContent);
    }
    return widgets.mapAttributes($node, $labelsContentAdjusted);
  }
});

module.exports = {};
