define("widgets/cr-multi-select", ["widgets/cr-multi-select.htm"], function (template) {
  cr.widgets.addElementHandler({
    type: "cr-multi-select",
    process: function ($node) {
      const $rv = $(template);
      cr.widgets.mapAttributes($node, $rv);
      return $rv;
    }
  });
});

ko.bindingHandlers.multiSelect = {
  init: function (el) {
    $(el).select2({ closeOnSelect: false });
  },
  update: function (el, _, ab) {
    const $select = $(el),
      isRequired = $select.is("[required]"),
      { selectedOptions } = ab?.() ?? {},
      options = selectedOptions?.() ?? [],
      validationBorder = isRequired && !options.some(f => f) ? "1px solid red" : "";

    $select.siblings(".select2-container").css("border-bottom", validationBorder);
    $select.val(options);
    $select.trigger("change");
  }
};
