import { disposeStore, instantiateStore, getStoreName } from "./helpers";
import { IStore, StoreConstructor } from "./store";

/**
 * Registry/lookup of Store instances.
 *
 * @private Intended for internal framework use only.
 */
export class RootStore {
  /** The global singleton Root Store instance */
  static readonly Instance = Object.freeze(new RootStore());

  /** @ignore */
  $id = 0;
  /** @ignore */
  $createdBy = "<app>";

  constructor(private stores: { [key: string]: IStore } = {}) {}

  /** Clears all registered stores */
  disposeAll = () =>
    Object.keys(this.stores).forEach(key => {
      disposeStore(this.stores[key]);
    });

  /**
   * Retrieves an instance of `StoreType`
   *
   * @param  {StoreConstructor|string} StoreType the type or name of Store to retrieve
   * @returns {TStore} instance of Store
   */
  getStore = <TStore extends IStore>(StoreType: StoreConstructor<TStore> | string): TStore => {
    if (StoreType == null) {
      throw Error("Cannot retrieve null store type");
    }

    const storeName = getStoreName(StoreType);

    return (this.stores[storeName] as TStore) || null;
  };

  /**
   * Registers the instance of a Store to be provided by `getStore(<StoreType>)`
   *
   * @param  {StoreConstructor} StoreType the type of Store to register
   * @param  {TStore} instance the instance of `StoreType` to be registered
   */
  register = <TStore extends IStore>(StoreType: StoreConstructor<TStore>, createdBy: string, instance?: TStore): TStore => {
    const store = instance == null ? instantiateStore(StoreType, this, createdBy) : instance;
    const storeName = getStoreName(StoreType);
    this.stores[storeName] = store;
    return store as TStore;
  };
}

// FOR DEBUGGING ONLY -- DO NOT WRITE PRODUCTION CODE AGAINST THIS!
// tslint:disable-next-line:no-string-literal
window["CRRootStore"] = RootStore.Instance;

export default RootStore.Instance;
