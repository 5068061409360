/**
 * Checks if this code is being executed inside an iFrame window
 */
export default function isFrameWindow() {
  // See https://stackoverflow.com/questions/326069/how-to-identify-if-a-webpage-is-being-loaded-inside-an-iframe-or-directly-into-t
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}
