define("widgets/cr-daterange", ["framework/globalUtils/koNodePreprocessor", "framework/koMapper/plugins/advancedSearch"], function(
  widgets,
  advSearch
) {
  /*******************************************************************************************************************

     This module defines TWO new widget tags

     cr-single-date

     and

     cr-date-range

     which integrate with the daterangepicker utility: https://github.com/dangrossman/bootstrap-daterangepicker

     cr-date-range uses the utility as it was originally designed to be used, while cr-single-date, through some hacks,
     hides the second calendar, allowing the user to select a single date, while still using the convenience labels
     like "Yesterday" or "14 days ago."  Note that the utility does expose a singleDatePicker mode, but this hides
     the convenience labels, basically turning the utility into a dumb date picker.

     Dependencies: advancedSearch plugin.  These widgets integrate with the advancedSearch plugin, taking in a reference
     to the searchVm to which this plugin was applied.

     Sample usage

     <cr-single-date options="searchVm: listVm.searchVm, dates: 't,y,7da,14da', defaultStart: startDate, bindStart: lastPaidDate">
         <i class="fa fa-money fa-fw"></i> Last paid
         <span></span> <b class="caret"></b>
     </cr-single-date>

     Note that you may put whatever content you like in the tags to style the dropdown button, and the widget will keep
     it.  You may also apply any attributes, or other data bindings to the widget tags, and they will be brought along.

     cr-single-date and cr-date-range share the same options, documented below.  Note that some options only apply
     to cr-date-range, and are so noted.

     Option         Which widget    Description
     -----------------------------------------------------------------------------------------------------------------
     searchVm     | both          | The searchVm which has had the advancedSearch plugin applied.
     -----------------------------------------------------------------------------------------------------------------
     dates        | both          | The date convenience labels to expose.  Should be a comma delimited list.  For
                  |               | specific options available, check the code below.
     -----------------------------------------------------------------------------------------------------------------
     defaultStart | both          | The default start date value.  Can be a plain property, observable, or function
                  |               | on the searchVm.
     -----------------------------------------------------------------------------------------------------------------
     defaultEnd   | cr-date-range | The default end date value.  Same as defaultStart.
     -----------------------------------------------------------------------------------------------------------------
     bindStart    | both          | The date value for the start date of the range (or only date when used with
                  |               | cr-single-date).  This can either be a plain, random observable of the searchVm,
                  |               | or, if this is one of the canned advancedSearch properties that automatically bind
                  |               | to the hash, and update the filters bar, then use the property name in the
                  |               | advancedSearch plugin's advancedSearchResultsMap map.  For example, to bind to
                  |               | the last paid date, use lastPaidDate NOT lastPaid, even though lastPaid is the
                  |               | actual property in the searchVm.
     -----------------------------------------------------------------------------------------------------------------
     bindEnd      | cr-date-range | The end date, for date ranges.  Same as above, but, as noted in the code, this is
                  |               | only supported for plain properties that are not in the advancedSearchResultsMap.
                  |               | Currently date ranges are only bound to non-advanced search properties, startDate
                  |               | and endDate in particular.  Opening this up to support binding either end of a
                  |               | date range to two different filter properties could be done easily, (mainly would
                  |               | just need a multi-property version of setFilterItem) but it's just not needed
                  |               | at the moment.
    *******************************************************************************************************************/

  var ALL_DATES_VALUE = moment().add(2, "years");

  $.fn.dateRangeSingleDateMode = function() {
    var self = this;

    return this.on("show.daterangepicker", function(e, picker) {
      //hide the stuff on the right
      picker.container.addClass("single-date-select");
      var leftCalendar = picker.container.find(".calendar.left");

      //keep the two calendars in sync
      if (!leftCalendar.hasClass("cr-sync-to-end-date")) {
        leftCalendar.addClass("cr-sync-to-end-date").on("click", "td.available", function() {
          var data = self.data("daterangepicker");
          data.setEndDate(data.startDate);
        });
      }
    });
  };

  ko.bindingHandlers.dateRange = {
    init: function(element, valueAccessor, ab, viewModel) {
      var options = valueAccessor(),
        ranges = {
          Today: { key: "t", value: [moment(), moment()] },
          Yesterday: { key: "y", value: [moment().subtract(1, "days"), moment().subtract(1, "days")] },
          "Last 7 Days": { key: "l7", value: [moment().subtract(6, "days"), moment()] },
          "Last 30 Days": { key: "l30", value: [moment().subtract(30, "days"), moment()] },
          "Last 45 Days": { key: "l45", value: [moment().subtract(45, "days"), moment()] },
          "Last 60 Days": { key: "l60", value: [moment().subtract(60, "days"), moment()] },
          "Last 90 Days": { key: "l90", value: [moment().subtract(90, "days"), moment()] },

          // these represent the same interval as the real-time receivables
          "31 through 60": { key: "r60", value: [moment().subtract(60, "days"), moment().subtract(31, "days")] },
          "61 through 90": { key: "r90", value: [moment().subtract(90, "days"), moment().subtract(61, "days")] },
          "91 through 120": { key: "r120", value: [moment().subtract(120, "days"), moment().subtract(91, "days")] },

          "7 Days ago": { key: "7da", value: [moment().subtract(6, "days"), moment().subtract(6, "days")] },
          "14 Days ago": { key: "14da", value: [moment().subtract(13, "days"), moment().subtract(13, "days")] },
          "30 Days ago": { key: "30da", value: [moment().subtract(29, "days"), moment().subtract(29, "days")] },
          "45 Days ago": { key: "45da", value: [moment().subtract(44, "days"), moment().subtract(44, "days")] },
          "60 Days ago": { key: "60da", value: [moment().subtract(59, "days"), moment().subtract(59, "days")] },
          "90 Days ago": { key: "90da", value: [moment().subtract(89, "days"), moment().subtract(89, "days")] },

          "This Week": {
            key: "tw",
            value: [
              moment()
                .startOf("week")
                .add(1, "days"),
              moment()
                .endOf("week")
                .add(1, "days")
            ]
          },
          "Next Week": {
            key: "nw",
            value: [
              moment()
                .add(1, "weeks")
                .startOf("week")
                .add(1, "days"),
              moment()
                .add(1, "weeks")
                .endOf("week")
                .add(1, "days")
            ]
          },
          "Week after next": {
            key: "2w",
            value: [
              moment()
                .add(2, "weeks")
                .startOf("week")
                .add(1, "days"),
              moment()
                .add(2, "weeks")
                .endOf("week")
                .add(1, "days")
            ]
          },
          "Next 30 Days": { key: "n30", value: [moment().subtract(), moment().add(30, "days")] },

          "This Month": { key: "tm", value: [moment().startOf("month"), moment().endOf("month")] },
          "Next Month": {
            key: "nm",
            value: [
              moment()
                .add(1, "months")
                .startOf("month"),
              moment()
                .add(1, "months")
                .endOf("month")
            ]
          },
          "Last Month": {
            key: "lm",
            value: [
              moment()
                .subtract(1, "month")
                .startOf("month"),
              moment()
                .subtract(1, "month")
                .endOf("month")
            ]
          },
          "All Dates": { key: "_all_", value: [ALL_DATES_VALUE, ALL_DATES_VALUE] }
        },
        rangesMapped = {};
      for (var key in ranges) {
        if (ranges.hasOwnProperty(key) && options.dates.indexOf(ranges[key].key) > -1) {
          rangesMapped[key] = ranges[key].value;
        }
      }

      var searchVm = options.searchVm,
        defaultStart = searchVm[options.defaultStart],
        defaultEnd = searchVm[options.defaultEnd],
        bindStart = options.bindStart,
        bindEnd = options.bindEnd,
        advSearchStartPacket = advSearch.advancedSearchResultsMap[bindStart],
        suppressHashChange = options.suppressHashChange;

      function getDefaultStartDate() {
        return typeof defaultStart === "function" ? defaultStart() : defaultStart;
      }

      function getDefaultEndDate() {
        return typeof defaultEnd === "function" ? defaultEnd() : defaultEnd;
      }

      var dateRangeResult = $(element).daterangepicker(
        {
          ranges: rangesMapped,
          startDate: getDefaultStartDate(),
          endDate: options.mode === "single" ? getDefaultStartDate() : getDefaultEndDate(),
          applyClass: "btn-primary",
          showDropdowns: options.showDropdowns
        },
        function(start, end) {
          if (options.mode === "single") {
            $(element)
              .data("daterangepicker")
              .setEndDate(start);
            if (advSearchStartPacket) {
              searchVm.setFilterItem(advSearchStartPacket.vm, start.format("YYYY-MM-DD"));
            } else {
              searchVm[bindStart](start.format("YYYY/MM/DD"));
              searchVm.fireOffSearch();
            }
          } else {
            if (advSearch.advancedSearchResultsMap[bindStart] || advSearch.advancedSearchResultsMap[bindEnd]) {
              throw "Not implemented - right now, this binding only supports a date range of dates that don't show up in the filter bar - startDate and endDate " +
                "for example.  This could be implemented relatively easily, but isn't needed at the moment.";
            } else {
              //this is a hack, but the best I can likely do with this utility.  It'll always pass in moments, so if they're both
              //invalid, that should mean that the user chose "all dates" which has null for both.
              var allDates = start.isSame(ALL_DATES_VALUE, "day") && end.isSame(ALL_DATES_VALUE, "day");

              if (allDates && !suppressHashChange) {
                cr.globalHashManager.applyTheseHashChanges([{ key: "startdate" }, { key: "enddate" }, { key: "all-dates", value: "true" }]);
              } else {
                searchVm[bindStart](start.format("YYYY/MM/DD"));
                searchVm[bindEnd](end.format("YYYY/MM/DD"));
                searchVm.fireOffSearch();
              }
            }
          }
        }
      );

      var disposables = [];

      //subscriptions below will keep everything sync'd after the hash changes
      if (options.mode === "single") {
        dateRangeResult.dateRangeSingleDateMode();
        disposables.push(
          searchVm[advSearchStartPacket ? advSearchStartPacket.vm : bindStart].subscribe(function(newVal) {
            dateRangeResult.data("daterangepicker").setStartDate(moment(newVal || getDefaultStartDate()));
            dateRangeResult.data("daterangepicker").setEndDate(moment(newVal || getDefaultStartDate()));
          })
        );
      } else {
        disposables.push(
          searchVm[bindStart].subscribe(function(newVal) {
            dateRangeResult.data("daterangepicker").setStartDate(moment(newVal || getDefaultStartDate()));
          })
        );
        disposables.push(
          searchVm[bindEnd].subscribe(function(newVal) {
            dateRangeResult.data("daterangepicker").setEndDate(moment(newVal || getDefaultEndDate()));
          })
        );
      }

      //dispose of the daterangepicker
      ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
        var widget = $(element).data("daterangepicker");

        //if the daterangepicker is still there and can be disposed
        if (widget && typeof widget.remove === "function") {
          widget.remove();
        }

        //remove any other subscriptions
        ko.utils.arrayForEach(disposables, function(disposable) {
          if (disposable && typeof disposable.dispose === "function") {
            disposable.dispose();
          }
        });

        disposables.length = 0;
      });
    }
  };

  var dataBindStringSingle =
      "data-bind=\"dateRange: { searchVm: #{searchVm}, showDropdowns: #{showDropdowns}, mode: 'single', dates: [#{dates}], defaultStart: '#{defaultStart}', bindStart: '#{bindStart}' }\"",
    dataBindStringRange =
      "data-bind=\"dateRange: { searchVm: #{searchVm}, showDropdowns: #{showDropdowns}, dates: [#{dates}], defaultStart: '#{defaultStart}', defaultEnd: '#{defaultEnd}', bindStart: '#{bindStart}', bindEnd: '#{bindEnd}', suppressHashChange: '#{suppressHashChange}' }\"";

  widgets.addElementHandler({
    type: "cr-single-date",
    process: processDateWidget.bind(null, "<div " + dataBindStringSingle + "></div>")
  });

  widgets.addElementHandler({
    type: "cr-date-range",
    process: processDateWidget.bind(null, "<div " + dataBindStringRange + "></div>")
  });

  function processDateWidget(baseString, $node) {
    var contents = $node.contents(),
      rawOptions = $node.attr("options").replace(/(searchVm|defaultStart|defaultEnd|bindStart|bindEnd)\s*:\s*((?:\w|\.)+)/g, function(a, prop, val) {
        return prop + ": '" + val + "'"; //sneak in some quotes so eval doesn't puke
      }),
      options = eval("({" + rawOptions + "})"),
      baseHtml = baseString
        .replace(/#{searchVm}/, options.searchVm)
        .replace(/#{dates}/, "'" + options.dates.replace(/\,/g, "','") + "'")
        .replace(/#{showDropdowns}/, options.showDropdowns)
        .replace(/#{defaultStart}/, options.defaultStart)
        .replace(/#{defaultEnd}/, options.defaultEnd)
        .replace(/#{bindStart}/, options.bindStart)
        .replace(/#{bindEnd}/, options.bindEnd)
        .replace(/#{suppressHashChange}/, options.suppressHashChange);
    return widgets.mapAttributes($node, $(baseHtml)).append(contents);
  }

  return ALL_DATES_VALUE;
});
