import { Logger } from "@cr/core/logger";
import rootStore from "@cr/mobx-stores/rootStore";
import { IStore, StoreArguments, StoreConstructor } from "./store";

const camelcase = name => (name ? name[0].toLowerCase() + name.substr(1) : name);

const log = Logger.create("Store");

/** Safely activate a Store */
export const activateStore = (store: IStore) => {
  if (store != null && typeof store.activate === "function") {
    log.trace(`Activating ${getStoreName(store)}...`);
    store.activate();
    log.debug(`Activated ${getStoreName(store)}`);
  }
};

/** Safely dispose a Store */
export const disposeStore = (store: IStore) => {
  if (store != null && typeof store.dispose === "function") {
    log.trace(`Disposing ${getStoreName(store)}...`);
    store.dispose();
    log.debug(`Disposed ${getStoreName(store)}`);
  }
};

/** 
 * Gets the name of a Store from its class/constructor 

 * @param storeInstanceOrClass Store class/constructor or instance from which to retrieve the name
 * 
 * Looks at the following:
 * * StoreType.storeName: Symbol("<StoreName>")
 * * StoreType.name: string (default ES behavior, but Babel rewrites it)
 */
export const getStoreName = (storeInstanceOrClass: StoreConstructor<any> | IStore & { constructor? } | string): string => {
  if (typeof storeInstanceOrClass === "string") {
    return storeInstanceOrClass as string;
  }

  if (typeof storeInstanceOrClass !== "function") {
    const instance = storeInstanceOrClass;
    return `${getStoreName(instance.constructor)}@${instance.$id}(${instance.$createdBy})`;
  }

  const { storeName, name } = storeInstanceOrClass as any;

  return camelcase((storeName && (/Symbol\((.*?)\)/.exec(storeName.toString())[1] as string)) || name);
};

let __id = 0;

/**
 * Creates a new instance of a Store
 *
 * @param StoreType type of Store to create
 * @param args Store arguments
 */
export const instantiateStore = <TStore extends IStore>(StoreType: StoreConstructor<any>, args: StoreArguments, createdBy: string): TStore => {
  if (StoreType && StoreType.isPersistent && args != rootStore) {
    return rootStore.register(StoreType, createdBy);
  }

  const storeName = getStoreName(StoreType);
  log.trace(`Instantiating ${storeName}`);

  const $id = (__id += 1);
  const $createdBy = createdBy;
  const store = new StoreType({ $id, $createdBy, ...args });

  // Make sure that $id and $createdBy have been set
  store.$id = store.$id || $id;
  store.$createdBy = store.$createdBy || $createdBy;
  log.debug(`Instantiated ${getStoreName(store)}`);

  return store;
};

/** Safely invalidate a Store's cache */
export const invalidateStoreCache = (store: IStore) => {
  if (store != null && typeof store.invalidateCache === "function") {
    log.trace(`Invalidating cache ${getStoreName(store)}...`);
    store.invalidateCache();
    log.debug(`Invalidated cache ${getStoreName(store)}`);
  }
};
